import React, { useState, useContext, useEffect } from "react";
import { TrainingsContext } from "../../context/TrainingsContext";
import { AvatarsContext } from "../../context/AvatarsContext";
import { ModalContext } from "../../context/ModalContext";
import { getValue } from "../../utils";
import ReactSwitch from "react-switch";

const TrainingForm = ({ handleCancel }) => {
  const { alert } = useContext(ModalContext);
  const [importContent, setImportContent] = useState(false);
  const { avatar, getSingleAvatar } = useContext(AvatarsContext);
  const { training, spinner, setTraining, saveTraining, setPropertyTraining } =
    useContext(TrainingsContext);

  useEffect(() => {
    return () => setTraining(null);
  }, []);

  const handleCallback = () => getSingleAvatar(avatar.avatar_id);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (training.name === "") {
      return alert("You must add a name to your training.");
    }
    training.avatar_id = avatar.avatar_id;
    training.import = importContent;
    saveTraining(training, handleCallback);
  };

  return (
    <form onSubmit={handleSubmit} className="container px-0">
      <label>Training Name</label>
      <input
        type="text"
        value={getValue(training, "name")}
        className="form-control mb-3"
        onChange={(e) => setPropertyTraining("name", e.target.value)}
      />
      <label>Training Dataset File (.csv) (optional)</label>
      <input
        type="file"
        className="form-control mb-4"
        onChange={(e) => setPropertyTraining("file", e.target.files[0])}
      />
      <div className="row">
        <div className="col-6">
          <label>Import content as outputs</label>
        </div>
        <div className="col-6 text-end">
          <ReactSwitch checked={importContent} onChange={setImportContent} />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-6">
          <button
            type="submit"
            disabled={spinner}
            className="btn btn-primary w-100"
          >
            {spinner ? <div className="spinner-border"></div> : "Save"}
          </button>
        </div>
        <div className="col-6">
          <button
            type="button"
            onClick={handleCancel}
            className="btn btn-link w-100 text-muted"
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  );
};

export default TrainingForm;
