import React, { useRef, useContext, useEffect } from "react";
import { TemplatesContext } from "../../context/TemplatesContext";
import { ModalContext } from "../../context/ModalContext";
import { getValue } from "../../utils";
import TagForm from "../global/TagForm";
import { AuthContext } from "../../context/AuthContext";
import ReactSwitch from "react-switch";

const TemplateForm = ({ handleCancel, handleCallback }) => {
  const textArea = useRef(null);
  const { user } = useContext(AuthContext);
  const { alert } = useContext(ModalContext);
  const { template, spinner, setTemplate, saveTemplate, setPropertyTemplate } =
    useContext(TemplatesContext);

  useEffect(() => {
    return () => setTemplate(null);
  }, []);

  useEffect(() => {
    const setupHeight = () => {
      if (template.content === "") {
        textArea.current.style.height = "0px";
      } else {
        textArea.current.style.height = textArea.current.scrollHeight + "px";
      }
    };

    if (template && template !== null) {
      setTimeout(() => {
        setupHeight();
      }, 500);
    }
  }, [template]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (template.name === "") {
      return alert("You must add a name to your template.");
    }
    saveTemplate(
      {
        ...template,
        public: false,
        fields: String(template.fields).trim(),
      },
      handleCallback
    );
  };

  const renderPublicToggle = () => {
    if (user.staff && user.staff !== null) {
      return (
        <div className="row">
          <div className="col-6">
            <label>Set Template as Public</label>
          </div>
          <div className="col-6">
            <ReactSwitch
              checked={template.public}
              onChange={(checked) => setPropertyTemplate("public", checked)}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <form onSubmit={handleSubmit} className="container px-0">
      <label>Template Name</label>
      <input
        type="text"
        value={getValue(template, "name")}
        className="form-control mb-3"
        onChange={(e) => setPropertyTemplate("name", e.target.value)}
      />
      <TagForm
        tagValues={template.tags}
        modifier={(tags) => setPropertyTemplate("tags", tags)}
      />
      <label>Content</label>
      <label className="small text-muted">
        This is where you write your prompt using {"[brackets]"} for your custom
        fields.
      </label>
      <textarea
        ref={textArea}
        className="form-control mb-4"
        value={getValue(template, "content")}
        onChange={(e) => setPropertyTemplate("content", e.target.value)}
      />
      <label>Fields</label>
      <label className="small text-muted">
        This is where you write the fields you placed in [brackets] in your
        prompt.Separate them with commas.
      </label>
      <input
        type="text"
        className="form-control mb-4"
        value={getValue(template, "fields")}
        onChange={(e) => setPropertyTemplate("fields", e.target.value)}
      />
      <label>Type of Content</label>
      <label className="small text-muted">
        You can classify this template as "Ad", "Blog Post", "Caption", etc.
      </label>
      <input
        type="text"
        className="form-control mb-4"
        value={getValue(template, "type")}
        onChange={(e) => setPropertyTemplate("type", e.target.value)}
      />
      {renderPublicToggle()}
      <div className="row mt-3">
        <div className="col-6">
          {" "}
          <button
            type="button"
            onClick={handleCancel}
            className="btn btn-link w-100 text-muted"
          >
            Cancel
          </button>
        </div>
        <div className="col-6">
          <button
            type="submit"
            disabled={spinner}
            className="btn btn-primary w-100"
          >
            {spinner ? <div className="spinner-border"></div> : "Save"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default TemplateForm;
