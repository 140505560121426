export const SET_PROMPT = "SET_PROMPT";
export const SET_MESSAGE = "SET_MESSAGE";
export const SAVE_MESSAGE = "SAVE_MESSAGE";
export const SET_ENHANCING = "SET_ENHANCING";
export const APPEND_MESSAGE = "APPEND_MESSAGE";
export const CREATE_MESSAGE = "CREATE_MESSAGE";
export const CLEAR_MESSAGES = "CLEAR_MESSAGES";
export const ENHANCED_RECEIVED = "ENHANCED_RECEIVED";
export const MESSAGES_RECEIVED = "MESSAGES_RECEIVED";
export const GENERATING_MESSAGE = "GENERATING_MESSAGE";
export const SET_PROPERTY_MESSAGE = "SET_PROPERTY_MESSAGE";
export const APPEND_ENHANCED_TOKEN = "APPEND_ENHANCED_TOKEN";
export const SET_PROPERTY_MESSAGE_PLURAL = "SET_PROPERTY_MESSAGE_PLURAL";
