import React from "react";

const ManualOutputForm = ({
  prompt,
  setPrompt,
  maxTokens,
  temperature,
  setMaxTokens,
  setTemperature,
}) => {
  return (
    <div>
      <label>Prompt</label>
      <input
        type="text"
        value={prompt}
        className="form-control mb-3"
        onChange={(e) => setPrompt(e.target.value)}
      />
      <label>Temperature</label>
      <input
        type="text"
        value={temperature}
        className="form-control mb-3"
        onChange={(e) => setTemperature(e.target.value)}
      />
      <label>Max Tokens</label>
      <input
        type="text"
        value={maxTokens}
        className="form-control mb-3"
        onChange={(e) => setMaxTokens(e.target.value)}
      />
    </div>
  );
};

export default ManualOutputForm;
