import React, { useContext, useState, useEffect } from "react";
import moment from "moment";
import Auth from "./views/Auth";
import Panel from "./views/Panel";
import io from "socket.io-client";
import { BASE_URL } from "./utils";
import { parse } from "query-string";
import Landing from "./views/Landing";
import Checkout from "./views/Checkout";
import Walkthrough from "./views/Walkthrough";
import Modal from "./components/global/Modal";
import { Router, navigate } from "@reach/router";
import { AuthContext } from "./context/AuthContext";
import { ModalContext } from "./context/ModalContext";
import ErrorAlert from "./components/common/ErrorAlert";
import { OutputsContext } from "./context/OutputsContext";
import { AvatarsContext } from "./context/AvatarsContext";
import { MessagesContext } from "./context/MessagesContext";
import SuccessAlert from "./components/common/SuccessAlert";
import { CapacitorContext } from "./context/CapacitorContext";
import { toggleDarkMode, toggleLightMode } from "./utils/darkmode";
import { ConversationsContext } from "./context/ConversationsContext";
import Pricing from "./views/Pricing";
import AuthService from "./services/FirebaseService";
import { PurchasesContext } from "./context/PurchasesContext";

const Main = () => {
  const [firstLoad, setFirstLoad] = useState(true);
  const { alert } = useContext(ModalContext);
  const {
    setEnhancing,
    appendMessage,
    appendEnhancedToken,
    setPropertyMessagePlural,
  } = useContext(MessagesContext);
  const { prependOutput } = useContext(OutputsContext);
  const { avatars, getAvatars } = useContext(AvatarsContext);
  const { purchases, getPurchases } = useContext(PurchasesContext);
  const { setupDevice, setupPlatform } = useContext(CapacitorContext);
  const { user, getCurrentUser, userLoggedIn } = useContext(AuthContext);
  const { conversations, getConversations } = useContext(ConversationsContext);

  useEffect(() => {
    if (user !== null) {
      getAvatars();
      getPurchases();
      getConversations();
      if (
        !user.has_access &&
        !window.location.pathname.includes("setting") &&
        !window.location.pathname.includes("billing") &&
        !window.location.pathname.includes("thankyou") &&
        !window.location.pathname.includes("checkout") &&
        !window.location.pathname.includes("pricing")
      ) {
        navigate("/welcome");
      }
    }
  }, [user]);

  useEffect(() => {
    if (Array.isArray(conversations) && Array.isArray(avatars)) {
      if (conversations.length === 1 && avatars.length === 0) {
        if (Array.isArray(purchases)) {
          if (purchases.length === 0) {
            if (
              !window.location.pathname.includes("setting") &&
              !window.location.pathname.includes("billing") &&
              !window.location.pathname.includes("thankyou") &&
              !window.location.pathname.includes("checkout") &&
              !window.location.pathname.includes("pricing")
            ) {
              navigate("/welcome");
            }
          }
        }
      }
    }
  }, [conversations, avatars]);

  useEffect(() => {
    setupDevice();
    userLoggedIn();
    setupPlatform();
    handleURLParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!firstLoad) {
      setupSocket();
    }
  }, [firstLoad]);

  const handleLocalStorage = () => {
    const uid = window.localStorage.getItem("uid");
    if (uid && uid !== null) {
      const expiration = window.localStorage.getItem("uid_expiration");
      if (moment(expiration).isBefore(moment())) {
        window.localStorage.removeItem("uid");
        window.localStorage.removeItem("uid_expiration");
      } else {
        AuthService.setToken(uid);
        getCurrentUser();
      }
      navigate("/");
    }
  };

  const handleURLParams = () => {
    const searchParams = parse(window.location.search);
    if (searchParams.uid) {
      window.localStorage.setItem("uid", searchParams.uid);
      window.localStorage.setItem(
        "uid_expiration",
        moment().add(5, "minutes").format("YYYY-MM-DD HH:mm")
      );
    }
    handleLocalStorage();
  };

  useEffect(() => {
    if (user !== null) {
      if (firstLoad) {
        setFirstLoad(false);
      }

      if (user.dark_mode) {
        toggleDarkMode();
      } else {
        toggleLightMode();
      }

      const route = window.location.pathname;
      if (route.includes("login")) {
        navigate("/");
      }
    }
  }, [user]);

  const handleMessage = (data) => {
    appendMessage(data);
  };

  const handleMessageStream = (data) => {
    if (
      data.content &&
      data.content !== null &&
      !String(data.content).includes("undefined")
    ) {
      setPropertyMessagePlural(data.message_id, "content", data.content);
    }
  };

  const handleStreamEnhanced = (data) => {
    if (
      data.content &&
      data.content !== null &&
      !String(data.content).includes("undefined")
    ) {
      appendEnhancedToken(data.content);
    }
  };

  const handleOutput = (data) => {
    prependOutput(data);
  };

  const handleConnection = (socket) => {
    const room = `user-${user.user_id}`;
    socket.emit("join_room", room);
  };

  const handleError = (data) => {
    alert(data);
  };

  const handleFinishEnhanced = () => {
    setEnhancing(false);
    getCurrentUser();
  };

  const setupSocket = () => {
    const socket = io(BASE_URL, {
      transports: ["websocket"],
    });

    socket.on("connect", () => handleConnection(socket));

    socket.on("output", handleOutput);

    socket.on("message", handleMessage);

    socket.on("message_stream", handleMessageStream);

    socket.on("message_stream_end", handleFinishEnhanced);

    socket.on("message_stream_enhance", handleStreamEnhanced);

    socket.on("message_stream_enhance_end", handleFinishEnhanced);

    socket.on("error", handleError);
  };

  return (
    <div id="main" className="container-fluid px-0">
      <Router>
        <Pricing path="/pricing" />
        <Checkout path="/checkout/:product_id" />
        {user !== null
          ? [
              <Panel key="panel" path="/*" />,
              <Walkthrough key="welcome" path="/welcome" />,
            ]
          : [
              <Landing key="landing" path="/" />,
              <Auth key="auth" path="/auth/*" />,
            ]}
      </Router>
      <Modal />
      <ErrorAlert />
      <SuccessAlert />
    </div>
  );
};

export default Main;
