import React, { useState, useContext, useEffect } from "react";
import { TrainingsContext } from "../context/TrainingsContext";
import { OutputsContext } from "../context/OutputsContext";
import { AvatarsContext } from "../context/AvatarsContext";
import OutputCard from "../components/outputs/OutputCard";
import Pagination from "../components/global/Pagination";
import { ModalContext } from "../context/ModalContext";
import OutputForm from "../components/outputs/OutputForm";
import { setupTooltips } from "../utils";

const PAGE_SIZE = 24;

const Outputs = ({ avatar_id }) => {
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [selected, setSelected] = useState(null);
  const [selecting, setSelecting] = useState(false);
  const { confirm, clearModal, modalComponent } = useContext(ModalContext);
  const { training, setTraining } = useContext(TrainingsContext);
  const {
    outputs,
    deleteOutput,
    downloadOutputs,
    getAvatarOutputs,
    getTrainingOutputs,
  } = useContext(OutputsContext);
  const { avatar } = useContext(AvatarsContext);

  useEffect(() => {
    fetchOutputs(query);
  }, [avatar_id, training, query]);

  useEffect(() => {
    setSelected([]);
  }, [selecting]);

  useEffect(() => {
    if (Array.isArray(outputs)) {
      setupTooltips();
    }
  }, [outputs, page]);

  const fetchOutputs = (query) => {
    if (training && training !== null && training.training_id !== "") {
      getTrainingOutputs(training.training_id, { page, query });
    } else if (avatar_id) {
      getAvatarOutputs(avatar_id, { page, query });
    }
  };

  const handleBulkDelete = () => {
    selected.forEach((output_id) => {
      deleteOutput(output_id, fetchOutputs);
    });
    setSelecting(false);
    setSelected(null);
  };

  const handleDownload = () => {
    const current = outputs
      .filter(({ output_id }) => selected.includes(output_id))
      .map(({ prompt, text }) => ({ prompt, completion: text }));
    downloadOutputs(
      avatar,
      training !== null ? training : { name: "General" },
      current
    );
  };

  const handleConfirmDelete = () => {
    confirm(
      "Are you sure you want to delete the selected outputs? This action CANNOT be reversed.",
      handleBulkDelete
    );
  };

  const handleSelect = (output_id) => {
    let current = [...selected];
    let index = current.findIndex((id) => id === output_id);
    if (index === -1) {
      current.push(output_id);
    } else {
      current.splice(index, 1);
    }
    setSelected(current);
  };

  const handleOutput = () => {
    modalComponent("Generate Output", <OutputForm handleCancel={clearModal} />);
  };

  const handleDelete = (output) => {
    modalComponent(
      "Delete Output",
      <div>
        <p>Are you sure you want to delete this output?</p>
        <p>This action cannot be reversed.</p>
        <button
          className="btn btn-danger"
          onClick={() => deleteOutput(output.output_id, fetchOutputs)}
        >
          <i className="fa fa-trash me-1"></i> Delete
        </button>
      </div>
    );
  };

  const renderOutputs = () => {
    if (Array.isArray(outputs)) {
      if (training && training !== null) {
        if (training.status !== "succeeded") {
          return <p>This training hasn't finished training</p>;
        }
      }
      if (outputs.length === 0) {
        return <p>No outputs available.</p>;
      }
      return outputs.map((output) => (
        <OutputCard
          output={output}
          selected={selected}
          selecting={selecting}
          key={output.output_id}
          handleSelect={handleSelect}
          handleDelete={handleDelete}
          handleCallback={fetchOutputs}
        />
      ));
    }
  };

  const renderTraining = () => {
    if (training && training !== null) {
      return (
        <div className="mb-2">
          <span className="badge bg-accent fw-normal align-items-center">
            <button
              className="btn px-0 py-0 me-1 text-white"
              onClick={() => setTraining(null)}
            >
              <i className="fa fa-times"></i>
            </button>
            Training: {training.name}
          </span>
        </div>
      );
    }
  };

  const renderPagination = (size) => {
    if (Array.isArray(outputs)) {
      return (
        <Pagination
          size={size}
          currentPage={page}
          onPageChange={setPage}
          itemsPerPage={PAGE_SIZE}
          totalItems={outputs.length}
        />
      );
    }
  };

  return (
    <div id="outputs" className="container-fluid px-0">
      <div className="row mb-2 align-items-center">
        <div className="col-6 col-md-6 mb-2">
          <h3 className="hide-mobile">Outputs</h3>
          <div className="show-mobile">{renderPagination("sm")}</div>
        </div>
        <div className="col-6 col-md-6 mb-2 text-end">
          <button
            className="btn btn-round btn-outline-accent me-2"
            onClick={handleDownload}
          >
            <i className="fa fa-download"></i>
          </button>
          <button
            className="btn btn-round btn-outline-danger me-2"
            onClick={handleConfirmDelete}
          >
            <i className="fa fa-trash"></i>
          </button>
          <button
            className="btn btn-outline-accent"
            onClick={() => setSelecting(!selecting)}
          >
            <i className="far fa-check-square"></i>
            <span className="hide-mobile ms-2">
              Select{selecting ? "ing" : ""}
            </span>
          </button>
        </div>
      </div>
      <div className="row mb-2 align-items-center">
        <div className="col-12 col-md-6 mb-1 hide-mobile">
          {renderPagination()}
        </div>
        <div className="col-12 col-md-6 mb-1">
          <input
            type="text"
            value={query}
            className="form-control"
            placeholder="Search for content..."
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
      </div>
      <button
        className="btn show-mobile btn-outline-primary mb-3 w-100"
        onClick={handleOutput}
      >
        <i className="fa fa-plus me-2"></i>
        Output
      </button>
      {renderTraining()}
      {renderOutputs()}
      {renderPagination()}
    </div>
  );
};

export default Outputs;
