import React, { useEffect, useState } from "react";
import AvatarCard from "./AvatarCard";

const AvatarList = ({
  title,
  avatars,
  hideButton,
  fetchAvatars,
  handleCreate,
}) => {
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (query !== "") {
      fetchAvatars(query);
    }
  }, [query]);

  const renderAvatars = () => {
    if (Array.isArray(avatars)) {
      if (avatars.length === 0) {
        return <p>You don't have any custom avatars yet.</p>;
      }
      return (
        <div className="row">
          {avatars.map((avatar) => (
            <div key={avatar.avatar_id} className="col-12 col-md-6 col-lg-4">
              <AvatarCard avatar={avatar} />
            </div>
          ))}
        </div>
      );
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="container-fluid px-0 pb-4">
      <div className="row mb-3 align-items-center">
        <div className="col-8">
          <h1 className="text-capitalize text-gradient d-inline-block">
            {title}
          </h1>
        </div>
        {!hideButton && (
          <div className="col-4 text-end">
            <button
              onClick={handleCreate}
              className="btn btn-primary me-0 ms-auto"
            >
              <i className="fa fa-plus"></i>{" "}
              <span className="hide-mobile">Avatar</span>
            </button>
          </div>
        )}
      </div>
      <input
        type="text"
        value={query}
        className="form-control mb-3"
        placeholder="Search avatars..."
        onChange={(e) => setQuery(e.target.value)}
      />
      {renderAvatars()}
    </div>
  );
};

export default AvatarList;
