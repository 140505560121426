import React, { createContext, useReducer } from "react";
import { SET_SELECTED, TOGGLE_SETTINGS } from "../types/menu";
import MenuReducer from "../reducers/MenuReducer";

const initialState = {
  tabs: [
    {
      link: "/",
      name: "Fetch",
    },
    {
      link: "/hashtags",
      name: "Hashtags",
      icon: "fa fa-hashtag me-2",
    },
    {
      link: "/topics",
      name: "Topics",
      icon: "fa fa-lightbulb me-2",
    },
    {
      link: "/avatars",
      name: "Avatars",
      icon: "fa fa-users me-2",
    },
    {
      link: "/tutorials",
      name: "Tutorials",
      icon: "fa fa-chalkboard me-2",
    },
    {
      link: "/billing",
      name: "Billing",
      icon: "fa fa-file-invoice me-2",
    },
    {
      link: "/settings",
      name: "Settings",
      icon: "fa fa-cog me-2",
    },
  ],
  selected: "",
  showSettings: false,
};

export const MenuContext = createContext(initialState);

export const MenuProvider = ({ children }) => {
  const [state, dispatch] = useReducer(MenuReducer, initialState);

  const setSelected = (name) => {
    dispatch({ type: SET_SELECTED, payload: name });
  };

  const toggleSettings = () => {
    dispatch({ type: TOGGLE_SETTINGS });
  };
  return (
    <MenuContext.Provider value={{ ...state, setSelected, toggleSettings }}>
      {children}
    </MenuContext.Provider>
  );
};
