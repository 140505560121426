import React, { useContext } from "react";
import { S3_ENDPOINT } from "../../utils";
import { Link } from "@reach/router";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import { AvatarsContext } from "../../context/AvatarsContext";

const AvatarCard = ({ avatar }) => {
  const { user } = useContext(AuthContext);
  const { deleteAvatar } = useContext(AvatarsContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  const handleDelete = () => {
    modalComponent(
      "Delete Avatar",
      <div>
        <p>
          Are you sure you want to delete this avatar? All your content and
          outputs will be lost
        </p>
        <p>This action CANNOT be undone.</p>
        <div className="row">
          <div onClick={clearModal} className="col-6">
            <button className="btn text-muted w-100">Cancel</button>
          </div>
          <div className="col-6">
            <button
              onClick={() => deleteAvatar(avatar.avatar_id)}
              className="btn btn-danger w-100"
            >
              <i className="fa fa-trash me-2"></i> Delete
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderImage = () => {
    if (avatar && avatar !== null) {
      const { file } = avatar;
      if (file && file !== null) {
        return (
          <img
            src={`${S3_ENDPOINT}/${file.name}.${file.type}`}
            className="user-img m-auto round"
            alt={avatar.name}
          />
        );
      }
    }
    return <i className="fa fa-user-circle text-muted fa-3x"></i>;
  };

  return (
    <div className="card position-relative bg-light p-3 mb-3">
      <div className="row align-items-center">
        <div className="col-3">{renderImage()}</div>
        <div className="col-8 col-md-9">
          <h3 className="h4 bold mb-0">{avatar.name}</h3>
        </div>
      </div>
      <Link
        to={`/avatars/${avatar.avatar_id}`}
        className="btn btn-view btn-primary px-2"
      >
        <i className="fa fa-chevron-right"></i>
      </Link>
    </div>
  );
};

export default AvatarCard;
