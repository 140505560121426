import React, { useState, useContext, useEffect } from "react";
import { AvatarsContext } from "../../context/AvatarsContext";
import { ModalContext } from "../../context/ModalContext";
import AvatarsService from "../../services/AvatarsService";

const CombineAvatar = () => {
  const [name, setName] = useState("");
  const [myAvatars, setMyAvatars] = useState(null);
  const [targetAvatar, setTargetAvatar] = useState(null);
  const [targetTraining, setTargetTraining] = useState(null);
  const { avatar, combineAvatar } = useContext(AvatarsContext);
  const { clearModal } = useContext(ModalContext);

  useEffect(() => {
    AvatarsService.getMyAvatars().then((res) => {
      const { avatars } = res.data;
      setMyAvatars(avatars);
    });
  }, []);

  useEffect(() => {
    if (Array.isArray(myAvatars) && targetAvatar === null) {
      if (myAvatars.length > 0) {
        setTargetAvatar(myAvatars[0]);
      }
    }
  }, [myAvatars]);

  const handleSubmit = () => {
    combineAvatar(
      avatar.avatar_id,
      targetAvatar.avatar_id,
      targetTraining,
      name
    );
  };

  const handleChange = (e) => {
    const avatar_id = e.target.value;
    const current = myAvatars.find(
      (avatar) => parseInt(avatar.avatar_id) === parseInt(avatar_id)
    );
    if (current) {
      setTargetAvatar(current);
      setTargetTraining(current.selected_training);
    }
  };

  const renderAvatars = () => {
    if (Array.isArray(myAvatars)) {
      return myAvatars.map((avatar) => (
        <option key={avatar.avatar_id} value={avatar.avatar_id}>
          {avatar.name}
        </option>
      ));
    }
  };

  const renderTargetTraining = () => {
    if (targetAvatar !== null) {
      const { trainings } = targetAvatar;
      if (Array.isArray(trainings) && trainings.length > 0) {
        return (
          <div>
            <label>Target Training to Combine with</label>
            <select
              value={targetTraining}
              className="form-control mb-3"
              onChange={(e) => setTargetTraining(e.target.value)}
            >
              {trainings.map((training) => (
                <option value={training.training_id}>{training.name}</option>
              ))}
            </select>
          </div>
        );
      }
    }
  };

  return (
    <div>
      <p>
        This will create a new training with all generated outputs and the
        original training dataset for this avatar and the one you select.
      </p>
      <label>Target Avatar to Combine with</label>
      <select className="form-control mb-3" onChange={handleChange}>
        {renderAvatars()}
      </select>
      {renderTargetTraining()}
      <label>Name for the Generated Training</label>
      <input
        type="text"
        value={name}
        className="form-control mb-3"
        onChange={(e) => setName(e.target.value)}
      />
      <div className="row">
        <div className="col-6">
          <button className="btn btn-primary" onClick={handleSubmit}>
            <i className="fa fa-wrench "></i> Combine Avatar
          </button>
        </div>
        <div className="col-6">
          <button
            className="btn w-100 btn-link text-muted"
            onClick={clearModal}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default CombineAvatar;
