import React, { createContext, useContext, useReducer } from "react";
import PurchasesReducer from "../reducers/PurchasesReducer";
import InvoicesService from "../services/InvoicesService";
import PurchasesService from "../services/PurchasesService";
import {
  PURCHASES_RECIBIDAS,
  INVOICES_RECIBIDOS,
  SET_PURCHASE,
  SET_INVOICE,
  SET_PAYMENT_NEEDED,
} from "../types/purchases";
import { ModalContext } from "./ModalContext";

const initialState = {
  payment_needed: false,
  purchases: null,
  purchase: null,
  invoices: null,
};

export const PurchasesContext = createContext(initialState);

export const PurchasesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(PurchasesReducer, initialState);

  const { clearModal } = useContext(ModalContext);

  const setPaymentNeeded = (payload) => {
    dispatch({ type: SET_PAYMENT_NEEDED, payload });
  };

  const getSinglePurchase = (purchase_id) => {
    PurchasesService.getSinglePurchase(purchase_id).then((res) => {
      const { purchase } = res.data;
      dispatch({ type: SET_PURCHASE, payload: purchase });
    });
  };

  const getPurchases = () => {
    PurchasesService.getPurchases().then((res) => {
      const { purchases } = res.data;
      dispatch({ type: PURCHASES_RECIBIDAS, payload: purchases });
    });
  };

  const getPurchasesAdmin = (filters) => {
    PurchasesService.getPurchasesAdmin(filters).then((res) => {
      const { purchases } = res.data;
      dispatch({ type: PURCHASES_RECIBIDAS, payload: purchases });
    });
  };

  const getInvoices = () => {
    InvoicesService.getInvoices().then((res) => {
      const { invoices } = res.data;
      dispatch({ type: INVOICES_RECIBIDOS, payload: invoices });
    });
  };

  const getPurchase = (purchase_id) => {
    PurchasesService.getPurchase(purchase_id).then((res) => {
      const { purchase } = res.data;
      dispatch({ type: SET_PURCHASE, payload: purchase });
    });
  };

  const getInvoice = (invoice_id) => {
    InvoicesService.getSingleInvoice(invoice_id).then((res) => {
      const { invoice } = res.data;
      dispatch({ type: SET_INVOICE, payload: invoice });
    });
  };

  const cancelSubscription = (purchase_id, reason) => {
    PurchasesService.cancelSubscription(purchase_id, reason).then((res) => {
      setTimeout(getPurchases, 1500);
      getPurchases();
      clearModal();
    });
  };

  return (
    <PurchasesContext.Provider
      value={{
        ...state,
        getInvoice,
        getInvoices,
        getPurchase,
        getPurchases,
        getPurchasesAdmin,
        setPaymentNeeded,
        getSinglePurchase,
        cancelSubscription,
      }}
    >
      {children}
    </PurchasesContext.Provider>
  );
};
