import React, { useContext, useEffect } from "react";
import { TutorialsContext } from "../context/TutorialsContext";
import { Link } from "@reach/router";
import { MenuContext } from "../context/MenuContext";

const Tutorials = () => {
  const { tutorials, getTutorials } = useContext(TutorialsContext);
  const { setSelected } = useContext(MenuContext);

  useEffect(() => {
    getTutorials();
    setSelected("Tutorials");
  }, []);

  const renderTutorials = () => {
    if (Array.isArray(tutorials)) {
      return tutorials.map((tutorial) => (
        <div key={tutorial.tutorial_id} className="row mb-2 align-items-center">
          <div className="col-10">
            #{tutorial.order}- {tutorial.name}
          </div>
          <div className="col-2 text-end">
            <Link
              to={`/tutorials/${tutorial.order}`}
              className="btn btn-round btn-primary"
            >
              <i className="fa fa-play-circle"></i>
            </Link>
          </div>
        </div>
      ));
    }
  };

  return (
    <div className="container-fluid py-4">
      <h1 className="text-gradient d-inline-block">Tutorials</h1>
      <div className="card p-3 shadow">{renderTutorials()}</div>
    </div>
  );
};

export default Tutorials;
