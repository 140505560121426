import React, { useContext, useEffect } from "react";
import { combineComponents } from "./context";
import { CapacitorProvider } from "./context/CapacitorContext";
import { FiltersProvider } from "./context/FiltersContext";
import { OutputsProvider } from "./context/OutputsContext";
import { AvatarsProvider } from "./context/AvatarsContext";
import { PaymentSourcesProvider } from "./context/PaymentSourcesContext";
import { ConversationsProvider } from "./context/ConversationsContext";
import { MessagesProvider } from "./context/MessagesContext";
import { CheckoutProvider } from "./context/CheckoutContext";
import { InvoicesProvider } from "./context/InvoicesContext";
import { TutorialsProvider } from "./context/TutorialsContext";
import { ProductsProvider } from "./context/ProductsContext";
import { AnalyticsProvider } from "./context/AnalyticsContext";
import { TemplatesProvider } from "./context/TemplatesContext";
import { TrainingsProvider } from "./context/TrainingsContext";
import { ModalProvider } from "./context/ModalContext";
import { AuthProvider } from "./context/AuthContext";
import { MenuProvider } from "./context/MenuContext";
import { setupIonicReact } from "@ionic/react";
import {
  AppConfigContext,
  AppConfigProvider,
} from "./context/AppConfigContext";
import { PurchasesProvider } from "./context/PurchasesContext";
import { setupColor, setupTitle } from "./utils/appconfig";
import "@ionic/react/css/core.css";
import Main from "./Main";

setupIonicReact();

const AppContextProviderWrapper = combineComponents([
  AuthProvider,
  MenuProvider,
  AvatarsProvider,
  FiltersProvider,
  OutputsProvider,
  InvoicesProvider,
  ProductsProvider,
  MessagesProvider,
  CheckoutProvider,
  CapacitorProvider,
  TrainingsProvider,
  TutorialsProvider,
  AnalyticsProvider,
  TemplatesProvider,
  PurchasesProvider,
  ConversationsProvider,
  PaymentSourcesProvider,
]);

const AppContext = () => {
  const {
    accent,
    primary,
    app_name,
    app_tagline,
    firebaseConfig,
    getAppConfig,
  } = useContext(AppConfigContext);

  useEffect(() => {
    getAppConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useContext(() => {
    setupTitle(app_name, app_tagline);
    setupColor("primary", primary);
    setupColor("accent", accent);
  }, [primary, accent]);

  const renderApp = () => {
    if (firebaseConfig && firebaseConfig !== null) {
      return (
        <ModalProvider>
          <AuthProvider>
            <AppContextProviderWrapper>
              <Main />
            </AppContextProviderWrapper>
          </AuthProvider>
        </ModalProvider>
      );
    }
  };

  return <div>{renderApp()}</div>;
};

const App = () => {
  return (
    <AppConfigProvider>
      <AppContext />
    </AppConfigProvider>
  );
};

export default App;
