import React, { useRef, useEffect, useContext } from "react";
import PublicTemplates from "../../views/PublicTemplates";
import { ModalContext } from "../../context/ModalContext";
import MessagesService from "../../services/MessagesService";
import { MessagesContext } from "../../context/MessagesContext";
import { setupTooltips } from "../../utils";
import { TemplatesContext } from "../../context/TemplatesContext";

const MessageInputForm = ({ spinner, handleSubmit }) => {
  const textArea = useRef(null);
  const { setTemplate } = useContext(TemplatesContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { prompt, enhanced, enhancing, setPrompt, setEnhanced, setEnhancing } =
    useContext(MessagesContext);

  useEffect(() => {
    setupTooltips();
    if (prompt && prompt !== null) {
      setTemplate(null);
    }
    return () => {
      setPrompt("");
    };
  }, []);

  useEffect(() => {
    const setupHeight = () => {
      if (prompt === "") {
        textArea.current.style.height = "0px";
      } else {
        textArea.current.style.height = textArea.current.scrollHeight + "px";
      }
    };

    setupHeight();
  }, [textArea, prompt]);

  useEffect(() => {
    if (enhanced && enhanced !== null) {
      setPrompt(enhanced);
    }
  }, [enhanced]);

  const handleApply = (currentPrompt) => {
    setPrompt(currentPrompt);
    clearModal();
  };

  const handleEnhance = () => {
    setEnhancing(true);
    MessagesService.postMessage({
      content: `Transform this lackluster prompt into a more effective and engaging question or statement: "${prompt}". Write the instruction to get the result, not the result itself.`,
      enhance: true,
      stream: true,
    });
  };

  const handleTemplates = () => {
    modalComponent(
      "Templates",
      <div>
        <PublicTemplates
          hideEdit={true}
          hideCreate={true}
          handleApply={handleApply}
        />
        <button onClick={clearModal} className="btn text-muted w-100">
          Cancel
        </button>
      </div>
    );
  };

  return (
    <div id="message-input" className="card p-3">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setEnhancing(false);
          handleSubmit(prompt);
          setEnhanced(null);
          setPrompt("");
        }}
        className="row"
      >
        <div className="container-fluid mb-3">
          <textarea
            type="text"
            ref={textArea}
            value={prompt}
            className="form-control"
            placeholder="Write an instruction"
            onChange={(e) => setPrompt(e.target.value)}
          />
        </div>
        <div className="col-6">
          {String(prompt).length > 12 && (
            <button
              type="button"
              onClick={handleEnhance}
              disabled={enhancing}
              className="btn btn-sm px-3 btn-outline-primary me-2"
            >
              {enhancing ? (
                <div className="spinner-border small"></div>
              ) : (
                <span>
                  <i className="fas fa-magic me-2"></i>
                  Enhance
                </span>
              )}
            </button>
          )}
          <button
            type="button"
            disabled={enhancing}
            onClick={handleTemplates}
            className="btn btn-sm px-3 btn-outline-primary me-2"
          >
            <i className="fas fa-shapes me-2"></i> Templates
          </button>
        </div>
        <div className="col-6 text-end">
          <button
            type="submit"
            disabled={spinner || enhancing || String(prompt).length < 10}
            className="btn btn-primary ps-2"
          >
            {spinner ? (
              <div className="spinner-border"></div>
            ) : (
              <span>
                <i className="fa fa-paper-plane me-2"></i>
                Send
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default MessageInputForm;
