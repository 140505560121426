import { Link } from "@reach/router";
import React, { useContext } from "react";
import ConversationForm from "./ConversationForm";
import { ConversationsContext } from "../../context/ConversationsContext";
import { ModalContext } from "../../context/ModalContext";

const ConversationCard = ({ conversation, handleCallback }) => {
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { setConversation, deleteConversation } =
    useContext(ConversationsContext);

  const handleCancel = () => {
    setConversation(null);
    clearModal();
  };

  const handleClick = () => {
    if (typeof handleCallback === "function") {
      handleCallback();
    }
  };

  const handleEdit = () => {
    setConversation(conversation);
    modalComponent(
      "Edit Conversation",
      <ConversationForm handleCancel={handleCancel} />
    );
  };

  const handleDelete = () => {
    modalComponent(
      "Delete Conversation",
      <div>
        <p>
          Are you sure you want to delete this conversation? This action CANNOT
          be reversed.
        </p>
        <div className="row">
          <div className="col-6">
            <button onClick={clearModal} className="btn w-100 text-muted">
              Cancel
            </button>
          </div>
          <div className="col-6">
            <button
              onClick={() => deleteConversation(conversation.conversation_id)}
              className="btn w-100 btn-danger"
            >
              <i className="fa fa-trash me-2"></i>Delete
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="card position-relative bg-light mb-3">
      <div className="card-body pb-2">{conversation.name}</div>
      <div className="card-footer bg-light border-0 pb-3">
        <button onClick={handleEdit} className="btn btn-sm btn-round border">
          <i className="fa fa-edit"></i>
        </button>
        <button
          onClick={handleDelete}
          className="btn btn-sm btn-outline-danger btn-round ms-2"
        >
          <i className="fa fa-trash"></i>
        </button>
      </div>
      <Link
        to={`/conversations/${conversation.conversation_id}`}
        onClick={handleClick}
        className="btn btn-primary btn-view"
      >
        <i className="fa fa-comments"></i>
      </Link>
    </div>
  );
};

export default ConversationCard;
