import React, { useContext } from "react";
import ConversationCard from "./ConversationCard";
import { ConversationsContext } from "../../context/ConversationsContext";

const ConversationList = ({ size, handleCallback }) => {
  const { conversations } = useContext(ConversationsContext);
  const renderConversations = () => {
    if (Array.isArray(conversations)) {
      if (conversations.length === 0) {
        return <p>You don't have any conversations.</p>;
      }
      return conversations.map((conversation) => (
        <div
          key={conversation.conversation_id}
          className={size === "lg" ? "col-12" : "col-12 col-md-6 col-xl-4"}
        >
          <ConversationCard
            conversation={conversation}
            handleCallback={handleCallback}
          />
        </div>
      ));
    }
  };

  return <div className="row">{renderConversations()}</div>;
};

export default ConversationList;
