import React, { useEffect, useContext } from "react";
import { AvatarsContext } from "../context/AvatarsContext";
import AvatarForm from "../components/avatars/AvatarForm";
import AvatarList from "../components/avatars/AvatarList";
import AvatarTabs from "../components/avatars/AvatarTabs";
import { ModalContext } from "../context/ModalContext";
import { AuthContext } from "../context/AuthContext";
import Upgrade from "./Upgrade";
import { MenuContext } from "../context/MenuContext";

const Avatars = () => {
  const {
    avatars,
    setAvatar,
    getAvatars,
    clearAvatars,
    createAvatar,
    getPublicAvatars,
  } = useContext(AvatarsContext);
  const { user } = useContext(AuthContext);
  const { setSelected } = useContext(MenuContext);
  const { modalComponent, clearModal } = useContext(ModalContext);

  useEffect(() => {
    fetchAvatars();
    setSelected("Avatars");
  }, [window.location.pathname]);

  const fetchAvatars = (query) => {
    clearAvatars();
    setAvatar(null);
    if (window.location.pathname.includes("community")) {
      getPublicAvatars(query);
    } else {
      getAvatars(query);
    }
  };

  const handleCreate = () => {
    createAvatar();
    modalComponent("Create Avatar", <AvatarForm handleCancel={clearModal} />);
  };
  const renderContent = () => {
    if (user.has_access) {
      const title = window.location.pathname.includes("community")
        ? "Avatars"
        : "My Avatars";
      return (
        <div>
          <div className="row">
            <AvatarTabs />
          </div>
          <AvatarList
            hideButton={title !== "My Avatars"}
            handleCreate={handleCreate}
            fetchAvatars={fetchAvatars}
            avatars={avatars}
            title={title}
          />
        </div>
      );
    }
    return <Upgrade>{renderUpgradeContent()}</Upgrade>;
  };

  const renderUpgradeContent = () => {
    if (window.location.pathname.includes("community")) {
      return (
        <div>
          <p>
            Combining your avatar with known celebrities and content authorities
            like{" "}
            <span className="text-accent">
              Gary Vee, John Maxwell, Barbabra Corcoran and many more...
            </span>{" "}
            requires a valid Master subscription to BemodoAI.
          </p>
          <p>
            Click the button to subscribe now and use{" "}
            <span className="badge bg-accent mx-2 p-2">WELCOME</span> discount
            code to get 14 days of access for only $4.95 USD
          </p>
          <p>Then, $29.95 per month.</p>
        </div>
      );
    }
    return (
      <div>
        <p>
          Creating an avatar who thinks, writes and sounds just like you (or
          even better than you) is a paid feature of BemodoAI.
        </p>
        <p>
          Click the button to subscribe now and use{" "}
          <span className="badge bg-accent mx-2 p-2">WELCOME</span> discount
          code to get 14 days of access for only $4.95 USD
        </p>
        <p>Then, $29.95 per month.</p>
      </div>
    );
  };

  return (
    <div className="container-fluid px-0">
      <div className="bg-white shadow card p-4">{renderContent()}</div>
    </div>
  );
};

export default Avatars;
