import React, { useState, useContext, useEffect } from "react";
import parse from "html-react-parser";
import moment from "moment";
import { MessagesContext } from "../../context/MessagesContext";
import { ModalContext } from "../../context/ModalContext";

const GeneratorMessageCard = ({ message, handleCallback }) => {
  const { saveMessage } = useContext(MessagesContext);
  const [editedText, setEditedText] = useState("");
  const [editMode, setEditMode] = useState(false);
  const { success } = useContext(ModalContext);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      success("Copied to clipboard.");
    }
  }, [copied]);

  const handleCopy = () => {
    let input = document.createElement("textarea");
    input.value = message.content;
    input.id = "copy-input";
    document.body.appendChild(input);
    var copyText = document.getElementById("copy-input");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    navigator.clipboard.writeText(copyText.value).then(() => {
      setCopied(true);
    });
    input.remove();
  };

  const clearEdit = () => {
    setEditMode(false);
    setEditedText("");
  };

  const toggleFavorite = () => {
    let current = { ...message };
    current.favorite = !current.favorite;
    saveMessage(current, handleCallback);
    saveMessage(
      { favorite: current.favorite, message_id: current.user_message_id },
      handleCallback
    );
  };

  const handleEdit = () => {
    if (editMode) {
      clearEdit();
      return saveMessage({ ...message, content: editedText }, handleCallback);
    }
    setEditMode(true);
    setEditedText(message.content);
  };

  const renderMessage = () => {
    if (editMode) {
      return (
        <textarea
          rows="10"
          type="text"
          value={editedText}
          className="form-control mb-3"
          onChange={(e) => setEditedText(e.target.value)}
        />
      );
    }
    return parse(String(message.content).replace(/(?:\r\n|\r|\n)/g, "<br>"));
  };

  return (
    <div className="card shadow p-4 mb-3">
      <p className="text-muted">{message.prompt}</p>
      {renderMessage()}
      <div className="row align-items-center mt-3">
        <div className="col-12 col-md-6">
          <p className="small mb-0 mt-3 text-muted">
            {moment(message.createdAt).format("MMM Do YYYY HH:mm")}
          </p>
        </div>
        <div className="col-12 col-md-6 text-end">
          {message.role !== "user" && (
            <div>
              {editMode && (
                <button
                  onClick={clearEdit}
                  className="btn btn-sm btn-outline-danger me-2"
                >
                  <i className="fa fa-times"></i>
                </button>
              )}
              <button
                onClick={toggleFavorite}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Add to favorites"
                className={`btn btn-sm btn-round btn-${
                  message.favorite ? "warning border" : "outline-dark"
                } me-2`}
              >
                <i className="fa fa-star"></i>
              </button>
              <button
                onClick={handleEdit}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Edit content"
                className="btn btn-sm btn-round btn-outline-dark me-2"
              >
                <i className={`fa fa-${editMode ? "save" : "edit"}`}></i>
              </button>
              <button
                onClick={handleCopy}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Copy to clipboard"
                className="btn btn-sm btn-round btn-outline-dark"
              >
                <i className="fa fa-copy"></i>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GeneratorMessageCard;
