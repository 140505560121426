import React, { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";

const OutputInfo = ({ output }) => {
  const { clearModal } = useContext(ModalContext);

  return (
    <div className="container-fluid px-0">
      <span className="text-muted small">Prompt:</span>
      <p>{output.prompt}</p>
      <div className="row mx-0 border-bottom mb-3 pb-2">
        <div className="col-6 px-0">
          <span className="small">Temperature</span>
        </div>
        <div className="col-6 px-0 text-end">
          <span className="text-muted small">{output.temperature}</span>
        </div>
      </div>

      <div className="row mx-0 border-bottom mb-3 pb-2">
        <div className="col-6 px-0">
          <span className="small">Used Tokens</span>
        </div>
        <div className="col-6 px-0 text-end">
          <span className="text-muted small">
            {output.used_tokens} / {output.max_tokens}
          </span>
        </div>
      </div>

      <button onClick={clearModal} className="btn w-100 btn-primary">
        OK
      </button>
    </div>
  );
};

export default OutputInfo;
