import React, { useState, useContext } from "react";
import { AvatarsContext } from "../../context/AvatarsContext";
import { ModalContext } from "../../context/ModalContext";
import { AuthContext } from "../../context/AuthContext";
import SingleAvatarTabs from "./SingleAvatarTabs";
import OutputForm from "../outputs/OutputForm";
import CombineAvatar from "./CombineAvatar";
import RefineAvatar from "./RefineAvatar";
import SingleOutputForm from "../outputs/SingleOutputForm";
import TemplateApplyForm from "../templates/TemplateApplyForm";
import OutputSelectForm from "../outputs/OutputSelectForm";
import { OutputsContext } from "../../context/OutputsContext";
import PublicTemplates from "../../views/PublicTemplates";

const AvatarHeader = ({ fetchOutputs }) => {
  const [name, setName] = useState("");
  const [editMode, setEditMode] = useState(false);

  const { user } = useContext(AuthContext);

  const { generateOutput } = useContext(OutputsContext);
  const { avatar, saveAvatar } = useContext(AvatarsContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  const editModeOn = () => {
    setName(avatar.name);
    setEditMode(true);
    clearModal();
  };

  const handleEdit = () => {
    if (!editMode) {
      return modalComponent(
        "Caution",
        <div>
          <p>
            Your avatar name MUST match the name you will use in your training
            dataset
          </p>
          <div className="row mt-3">
            <div className="col-6">
              <button onClick={editModeOn} className="btn w-100 btn-primary">
                Rename
              </button>
            </div>
            <div className="col-6">
              <button onClick={clearModal} className="btn text-muted w-100">
                Cancel
              </button>
            </div>
          </div>
        </div>
      );
    }
    saveAvatar({ ...avatar, name });
    setEditMode(false);
    return setName("");
  };

  const handleApply = (prompt) => {
    generateOutput({
      prompt,
      maxTokens: 1500,
      temperature: 0.9,
      manual_mode: true,
      avatar_id: avatar.avatar_id,
    });
  };

  const handleSelect = (mode) => {
    clearModal();
    if (mode === "manual") {
      return setTimeout(() => {
        modalComponent("Manual Mode", <OutputForm handleCancel={clearModal} />);
      }, 500);
    }
    return setTimeout(() => {
      modalComponent(
        "Use a Template",
        <div>
          <PublicTemplates
            hideEdit={true}
            hideCreate={true}
            handleApply={handleApply}
          />
          <button onClick={clearModal} className="btn text-muted w-100">
            Cancel
          </button>
        </div>
      );
    }, 500);
  };

  const handleOutput = () => {
    modalComponent(
      "Generate Output",
      <OutputSelectForm handleSelect={handleSelect} />
    );
  };

  const handleRefine = () => {
    modalComponent("Train Avatar", <RefineAvatar />);
  };

  const handleCombine = () => {
    modalComponent("Combine Avatar", <CombineAvatar />);
  };

  const handleImport = () => {
    modalComponent(
      "Import Content",
      <SingleOutputForm
        handleCallback={fetchOutputs}
        avatar_id={avatar.avatar_id}
        handleCancel={clearModal}
      />
    );
  };

  const renderName = () => {
    if (avatar && avatar !== null) {
      if (editMode) {
        return (
          <form>
            <input
              type="text"
              value={name}
              className="form-control w-75 d-inline-block"
              onChange={(e) => setName(e.target.value)}
            />
            <button onClick={handleEdit} className="btn mx-2">
              <i className={`fa fa-${editMode ? "save" : "edit"}`}></i>
            </button>
          </form>
        );
      }
      return (
        <div className="d-flex align-items-center">
          <h1 className="h3 bold d-inline-block">{avatar.name}</h1>
          <button type="button" onClick={handleEdit} className="btn ms-2">
            <i className={`fa fa-${editMode ? "save" : "edit"}`}></i>
          </button>
        </div>
      );
    }
  };

  return (
    <div
      id="avatar-header"
      className="hide-mobile row align-items-center mb-3 px-2 bg-white pt-3"
    >
      <div className="col-12 col-md-6 mb-2">{renderName()}</div>
      <div className="col-12 col-md-6 text-md-end mb-2">
        {avatar.user_id === user.user_id ? (
          <div>
            <button className="btn border me-2" onClick={handleRefine}>
              <i className="fa fa-wrench"></i>
              <span className="ms-2">Train</span>
            </button>
            <button className="btn btn-accent me-2" onClick={handleImport}>
              <i className="fa fa-download"></i>
              <span className="ms-2">Import</span>
            </button>
            <button className="btn btn-primary" onClick={handleOutput}>
              <i className="fa fa-plus"></i>
              <span className="ms-2">Content</span>
            </button>
          </div>
        ) : (
          <button className="btn border me-2" onClick={handleCombine}>
            <i className="fa fa-random"></i>
            <span className="ms-2">Combine</span>
          </button>
        )}
      </div>
      <div className="container-fluid pt-2">
        <SingleAvatarTabs avatar={avatar} />
      </div>
    </div>
  );
};
export default AvatarHeader;
