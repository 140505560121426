import React, { useState, useContext } from "react";
import RefineAvatar from "./RefineAvatar";
import CombineAvatar from "./CombineAvatar";
import OutputForm from "../outputs/OutputForm";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import { AvatarsContext } from "../../context/AvatarsContext";

const AvatarDetails = () => {
  const [name, setName] = useState("");
  const [editMode, setEditMode] = useState(false);

  const { user } = useContext(AuthContext);

  const { avatar, saveAvatar } = useContext(AvatarsContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  const editModeOn = () => {
    setName(avatar.name);
    setEditMode(true);
    clearModal();
  };

  const handleEdit = () => {
    if (!editMode) {
      return modalComponent(
        "Caution",
        <div>
          <p>
            Your avatar name MUST match the name you will use in your training
            dataset
          </p>
          <div className="row mt-3">
            <div className="col-6">
              <button onClick={editModeOn} className="btn w-100 btn-primary">
                Rename
              </button>
            </div>
            <div className="col-6">
              <button onClick={clearModal} className="btn text-muted w-100">
                Cancel
              </button>
            </div>
          </div>
        </div>
      );
    }
    saveAvatar({ ...avatar, name });
    setEditMode(false);
    return setName("");
  };

  const handleOutput = () => {
    modalComponent("Generate Output", <OutputForm handleCancel={clearModal} />);
  };

  const handleRefine = () => {
    modalComponent("Refine Avatar", <RefineAvatar />);
  };

  const handleCombine = () => {
    modalComponent("Combine Avatar", <CombineAvatar />);
  };
  const renderName = () => {
    if (avatar && avatar !== null) {
      if (editMode) {
        return (
          <form>
            <input
              type="text"
              value={name}
              className="form-control w-75 d-inline-block"
              onChange={(e) => setName(e.target.value)}
            />
            <button onClick={handleEdit} className="btn mx-2">
              <i className={`fa fa-${editMode ? "save" : "edit"}`}></i>
            </button>
          </form>
        );
      }
      return (
        <div className="d-flex align-items-center">
          <h1 className="h3 bold d-inline-block">{avatar.name}</h1>
          <button type="button" onClick={handleEdit} className="btn ms-2">
            <i className={`fa fa-${editMode ? "save" : "edit"}`}></i>
          </button>
        </div>
      );
    }
  };
  return (
    <div className="row align-items-center bg-white px-2 py-3 br-10 my-3">
      <div className="col-12 col-md-6 mb-2">{renderName()}</div>
      <div className="col-12 col-md-6 text-md-end mb-2">
        {avatar.user_id === user.user_id ? (
          <div>
            <button className="btn border me-2" onClick={handleRefine}>
              <i className="fa fa-wrench"></i>
              <span className="ms-2">Refine</span>
            </button>
            <button className="btn btn-primary" onClick={handleOutput}>
              <i className="fa fa-plus"></i>
              <span className="ms-2">Output</span>
            </button>
          </div>
        ) : (
          <button className="btn border me-2" onClick={handleCombine}>
            <i className="fa fa-random"></i>
            <span className="ms-2">Combine</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default AvatarDetails;
