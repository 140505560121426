import React, { useState, useContext, useEffect } from "react";
import { ModalContext } from "../context/ModalContext";
import { ConversationsContext } from "../context/ConversationsContext";
import ConversationForm from "../components/conversations/ConversationForm";
import { AuthContext } from "../context/AuthContext";
import { MenuContext } from "../context/MenuContext";
import Upgrade from "./Upgrade";
import ConversationList from "../components/conversations/ConversationList";

const Conversations = () => {
  const [query, setQuery] = useState("");
  const { setSelected } = useContext(MenuContext);

  const { user } = useContext(AuthContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { getConversations, createConversation } =
    useContext(ConversationsContext);

  useEffect(() => {
    getConversations({ query });
    setSelected("Fetch");
  }, []);

  const handleCreateConversation = () => {
    createConversation();
    modalComponent(
      "Add Conversation",
      <ConversationForm handleCancel={clearModal} />
    );
  };

  const renderConversations = () => {
    if (!user.has_access) {
      return (
        <Upgrade>
          <p>
            Creating more conversations and producing more content with{" "}
            <span className="text-accent">
              Bemodo's AI Content Assitant "Fetch"
            </span>{" "}
            requires a subscription to BemodoAI.
          </p>
          <p>
            Click the button to subscribe now and use{" "}
            <span className="badge bg-accent mx-2 p-2">WELCOME</span> discount
            code to get 14 days of access for only $4.95 USD
          </p>
          <p>Then, $29.95 per month.</p>
        </Upgrade>
      );
    }
    return <ConversationList />;
  };

  return (
    <div className="container-fluid bg-white p-4 card">
      <div className="row mx-0 pb-2 mb-3 align-items-center">
        <div className="col-8 px-0">
          <h1 className="h2 mb-0 text-gradient d-inline-block">
            Conversations
          </h1>
        </div>
        <div className="col-4 px-0 text-end">
          <button
            className="btn btn-primary"
            onClick={handleCreateConversation}
          >
            + Add
          </button>
        </div>
      </div>
      <input
        type="text"
        value={query}
        className="form-control mb-3"
        placeholder="Search conversations..."
        onChange={(e) => setQuery(e.target.value)}
      />
      <div className="row">{renderConversations()}</div>
    </div>
  );
};

export default Conversations;
