import React, { useContext, useEffect } from "react";
import ConversationList from "../components/conversations/ConversationList";
import { ConversationsContext } from "../context/ConversationsContext";
import TemplateList from "../components/templates/TemplatesList";
import { TemplatesContext } from "../context/TemplatesContext";
import { MessagesContext } from "../context/MessagesContext";
import { ModalContext } from "../context/ModalContext";
import { setupTooltips } from "../utils";

const Templates = () => {
  const { prompt, setPrompt } = useContext(MessagesContext);
  const { getConversations } = useContext(ConversationsContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { templates, getPublicTemplates } = useContext(TemplatesContext);

  useEffect(() => {
    fetchTemplates();
    setupTooltips();
  }, []);

  const fetchTemplates = (params) => {
    getPublicTemplates(params);
  };

  const handleApply = (prompt) => {
    getConversations();
    setPrompt(prompt);
    clearModal();
    setTimeout(() => {
      modalComponent(
        "Choose Conversation",
        <ConversationList size="lg" handleCallback={clearModal} />
      );
    }, 500);
  };

  return (
    <div className="card p-4">
      <TemplateList
        size="lg"
        title="Templates"
        templates={templates}
        handleApply={handleApply}
        fetchTemplates={fetchTemplates}
      />
    </div>
  );
};

export default Templates;
