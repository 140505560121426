import React, { useReducer, createContext, useContext } from "react";
import AvatarsService from "../services/AvatarsService";
import AvatarsReducer from "../reducers/AvatarsReducer";
import {
  SET_AVATAR,
  CREATE_AVATAR,
  AVATARS_RECEIVED,
  SET_PROPERTY_AVATAR,
} from "../types/avatars";
import { navigate } from "@reach/router";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  spinner: false,
  output: null,
  avatars: null,
  avatar: null,
};

export const AvatarsContext = createContext(initialState);

export const AvatarsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AvatarsReducer, initialState);

  const { showModal, success, alert, clearModal } = useContext(ModalContext);

  const handleError = (error) => {
    dispatch({ type: HIDE_SPINNER });
    if (error.response) {
      if (error.response.data) {
        if (error.response.data.error) {
          if (error.response.data.error.code) {
            return alert(`Open AI Error: ${error.response.data.error.message}`);
          }
        }
      }
      if (error.response.status === 412) {
        return alert(
          "This is a premium feature. Please, upgrade your account."
        );
      }
    }
    alert(error);
  };

  const clearAvatars = () => {
    dispatch({ type: AVATARS_RECEIVED, payload: null });
  };

  const getAvatars = () => {
    AvatarsService.getMyAvatars().then((res) => {
      const { avatars } = res.data;
      dispatch({ type: AVATARS_RECEIVED, payload: avatars });
    });
  };

  const getSingleAvatar = (avatar_id) => {
    AvatarsService.getSingleAvatar(avatar_id).then((res) => {
      const { avatar } = res.data;
      dispatch({ type: SET_AVATAR, payload: avatar });
    });
  };

  const getPublicAvatars = () => {
    AvatarsService.getPublicAvatars().then((res) => {
      const { avatars } = res.data;
      dispatch({ type: AVATARS_RECEIVED, payload: avatars });
    });
  };

  const setAvatar = (avatar) => {
    dispatch({ type: SET_AVATAR, payload: avatar });
  };

  const createAvatar = () => {
    dispatch({ type: CREATE_AVATAR });
  };

  const setPropertyAvatar = (key, value) => {
    dispatch({ type: SET_PROPERTY_AVATAR, payload: { key, value } });
  };

  const saveAvatar = (avatar) => {
    dispatch({ type: SHOW_SPINNER });
    if (isNaN(parseInt(avatar.avatar_id))) {
      AvatarsService.postAvatar(avatar)
        .then((res) => {
          getSingleAvatar(res.data.avatar.avatar_id);
          dispatch({ type: HIDE_SPINNER });
          success("Avatar saved.");
          getAvatars();
          if (showModal) {
            clearModal();
          }
        })
        .catch(handleError);
    } else {
      AvatarsService.putAvatar(avatar)
        .then(() => {
          dispatch({ type: HIDE_SPINNER });
          getSingleAvatar(avatar.avatar_id);
          success("Avatar saved.");
          getAvatars();
          if (showModal) {
            clearModal();
          }
        })
        .catch(handleError);
    }
  };

  const syncAvatar = (avatar) => {
    AvatarsService.syncAvatar(avatar.avatar_id)
      .then(() => {
        getAvatars();
        success(
          `Avatar ${avatar.name} synced. It may take up to 15 minutes to train a avatar.`
        );
      })
      .catch(handleError);
  };

  const refineAvatar = (avatar) => {
    dispatch({ type: SHOW_SPINNER });
    AvatarsService.refineAvatar(avatar.avatar_id)
      .then(() => {
        getSingleAvatar(avatar.avatar_id);
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        success(
          `Avatar ${avatar.name} synced. It may take up to 15 minutes to train a avatar.`
        );
      })
      .catch(handleError);
  };

  const combineAvatar = (
    selected_avatar_id,
    target_avatar_id,
    target_training_id,
    name
  ) => {
    AvatarsService.combineAvatar(
      selected_avatar_id,
      target_avatar_id,
      target_training_id,
      name
    )
      .then(() => {
        navigate(`/avatars/${target_avatar_id}`);
        clearModal();
        success(
          `Avatar combined. It may take up to 15 minutes to train a avatar.`
        );
      })
      .catch(handleError);
  };

  const deleteAvatar = (avatar_id) => {
    AvatarsService.deleteAvatar(avatar_id).then(() => {
      success("Avatar deleted.");
      navigate("/");
      getAvatars();
      clearModal();
    });
  };

  return (
    <AvatarsContext.Provider
      value={{
        ...state,
        setAvatar,
        syncAvatar,
        getAvatars,
        saveAvatar,
        deleteAvatar,
        createAvatar,
        clearAvatars,
        refineAvatar,
        combineAvatar,
        getSingleAvatar,
        getPublicAvatars,
        setPropertyAvatar,
      }}
    >
      {children}
    </AvatarsContext.Provider>
  );
};
