import React, { useState, useContext, useEffect } from "react";
import { Link } from "@reach/router";
import { AuthContext } from "../../context/AuthContext";

const LoginForm = ({ isHome, setLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { spinner, signIn, reattempt } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    return () => {
      setEmail("");
      setPassword("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reattempt && email !== "" && password !== "") {
      signIn(email, password);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reattempt]);

  const handleSubmit = (e) => {
    e.preventDefault();
    signIn(email, password);
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div id="login-card">
      <h2 className="text-center text-gradient mb-3">Login with Email</h2>
      <div
        className={`card text-left text-white shadow-sm p-4 ${
          isHome ? "bg-black" : "bg-white"
        }`}
      >
        <form onSubmit={handleSubmit}>
          <label className={`${isHome ? "text-white" : "text-black"}`}>
            Email
          </label>
          <input
            type="email"
            className="form-control mb-3"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label className={`${isHome ? "text-white" : "text-black"}`}>
            Password{" "}
            <button
              className="btn btn-link text-muted text-left text-small py-0 mb-1"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                togglePassword();
              }}
            >
              <span className="text-montserrat text-small text-auto">
                {showPassword ? "Hide" : "Show"}
              </span>
            </button>
          </label>
          <input
            type={showPassword ? "text" : "password"}
            className="form-control mb-3"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Link to="/auth/recovery">Forgot your password?</Link>
          <button type="submit" className="btn btn-primary w-100 my-3">
            {spinner ? <div className="spinner-border"></div> : "Login"}
          </button>
        </form>
        <p className="text-center mb-0 mt-3 text-white">
          Don't have an account yet?
        </p>
        {typeof setLogin === "function" ? (
          <button onClick={setLogin} className="btn btn-link w-100">
            Create An Account
          </button>
        ) : (
          <Link to="/auth/signup" className="btn btn-link w-100">
            Create An Account
          </Link>
        )}
      </div>
    </div>
  );
};

export default LoginForm;
