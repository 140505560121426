import React, { useContext, useState } from "react";
import { OutputsContext } from "../../context/OutputsContext";
import ReactSwitch from "react-switch";
import { AuthContext } from "../../context/AuthContext";
import AutomaticOutputForm from "./AutomaticOutputForm";
import ManualOutputForm from "./ManualOutputForm";
import { AvatarsContext } from "../../context/AvatarsContext";

const OutputForm = ({ handleCancel }) => {
  const [amount, setAmount] = useState(1);
  const [prompt, setPrompt] = useState("Write a tweet");
  const [maxTokens, setMaxTokens] = useState("500");
  const [temperature, setTemperature] = useState("0.5");
  const [appendGeneral, setAppendGeneral] = useState(false);

  const { spinner, generateOutput } = useContext(OutputsContext);
  const { avatar } = useContext(AvatarsContext);
  const { user } = useContext(AuthContext);

  const [manualMode, setManualMode] = useState(true);

  const getPrompt = () => {
    if (manualMode || user.manual_mode) {
      return prompt;
    }
    return `Write a ${String(prompt).toLowerCase()} in the style of ${
      avatar.name
    } as the provided examples.`;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    generateOutput({
      amount,
      prompt: getPrompt(),
      manual_mode: manualMode,
      avatar_id: avatar.avatar_id,
      append_general: appendGeneral,
      maxTokens: parseInt(maxTokens),
      temperature: parseFloat(temperature),
    });
  };

  const renderForm = () => {
    if (manualMode) {
      return (
        <ManualOutputForm
          prompt={prompt}
          setPrompt={setPrompt}
          maxTokens={maxTokens}
          temperature={temperature}
          setMaxTokens={setMaxTokens}
          setTemperature={setTemperature}
        />
      );
    }
    return (
      <AutomaticOutputForm
        maxTokens={maxTokens}
        setPrompt={setPrompt}
        temperature={temperature}
        setMaxTokens={setMaxTokens}
        setTemperature={setTemperature}
      />
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      {/*<div className="row mb-2">
        <div className="col-8">Manual Mode</div>
        <div className="col-4 text-end">
          <ReactSwitch
            checked={
              manualMode !== user.manual_mode ? manualMode : user.manual_mode
            }
            onChange={(checked) => setManualMode(checked)}
          />
        </div>
          </div>*/}
      {renderForm()}
      <label>How many samples do you want?</label>
      <input
        type="number"
        value={amount}
        className="form-control mb-3"
        onChange={(e) => {
          const { value } = e.target;
          if (parseInt(value) > 100) {
            return setAmount(100);
          }
          setAmount(e.target.value);
        }}
      />
      {manualMode && (
        <div className="row mb-2">
          <div className="col-8">
            <h4>Double ideas with Fetch?</h4>
            <p className="small text-muted mb-0">
              If your training dataset has less than 200 samples, this might be
              a good idea.
            </p>
          </div>
          <div className="col-4 text-end">
            <ReactSwitch
              checked={appendGeneral}
              onChange={(checked) => setAppendGeneral(checked)}
            />
          </div>
          <p className="text-danger small">
            *Requires a topic in prompt to work properly. Include "about" and
            the topic in your prompt.
          </p>
        </div>
      )}
      <div className="row">
        <div className="col-6">
          <button
            type="button"
            className="btn w-100 btn-link text-muted"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
        <div className="col-6">
          <button
            type="submit"
            disabled={
              spinner ||
              (manualMode && !String(prompt).includes("about") && appendGeneral)
            }
            className="btn w-100 btn-primary"
          >
            {spinner ? <div className="spinner-border"></div> : "Generate"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default OutputForm;
