import React, { useEffect, useContext } from "react";
import Generator from "./Generator";
import { MenuContext } from "../context/MenuContext";

const TopicsGenerator = () => {
  const { setSelected } = useContext(MenuContext);

  useEffect(() => {
    setSelected("Hashtag Generator");
  }, []);
  return (
    <Generator
      base_prompt="Generate 2 to 5 trending topic ideas for "
      title="Trending Topics Generator"
      message_type_id={3}
    />
  );
};

export default TopicsGenerator;
