import React, { useRef, useEffect } from "react";
import TagForm from "../global/TagForm";

const OutputEditForm = ({
  value,
  modifier,
  tagValues,
  handleSave,
  tagModifier,
  handleCancel,
}) => {
  const textArea = useRef(null);

  useEffect(() => {
    if (textArea !== null) {
      if (textArea.current && textArea.current !== null) {
        setupHeight();
      }
    }
  }, [textArea]);

  const setupHeight = () => {
    textArea.current.style.height = textArea.current.scrollHeight + "px";
  };

  return (
    <div>
      <TagForm tagValues={tagValues} modifier={(tags) => tagModifier(tags)} />
      <textarea
        value={value}
        ref={textArea}
        className="form-control mb-3"
        onChange={(e) => modifier(e.target.value)}
      />
      <button className="btn btn-primary me-2" onClick={handleSave}>
        <i className="fa fa-save me-1"></i>Save
      </button>
      <button type="button" className="btn text-muted" onClick={handleCancel}>
        Cancel
      </button>
    </div>
  );
};

export default OutputEditForm;
