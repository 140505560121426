import React, { useContext } from "react";
import { AvatarsContext } from "../../context/AvatarsContext";
import { ModalContext } from "../../context/ModalContext";

const RefineAvatar = () => {
  const { clearModal } = useContext(ModalContext);
  const { spinner, avatar, refineAvatar } = useContext(AvatarsContext);
  return (
    <div>
      <p>
        This will train a new model with all generated outputs and all training
        files you have uploaded with content.
      </p>
      <div className="row">
        <div className="col-6">
          <button className="btn w-100 text-muted" onClick={clearModal}>
            Cancel
          </button>
        </div>
        <div className="col-6">
          <button
            className="btn w-100 btn-primary"
            onClick={() => refineAvatar(avatar)}
          >
            <i className="fa fa-wrench "></i>{" "}
            {spinner ? <div className="spinner-border"></div> : "Train"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RefineAvatar;
