import React, { useContext, useEffect, useState } from "react";
import TrainingCard from "./TrainingCard";
import { API_URL } from "../../utils";
import moment from "moment";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import TrainingForm from "./TrainingForm";
import { TrainingsContext } from "../../context/TrainingsContext";

const TrainingList = ({ title, avatar, trainings, fetchTrainings }) => {
  const [query, setQuery] = useState("");
  const { user } = useContext(AuthContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { setTraining, createTraining } = useContext(TrainingsContext);

  useEffect(() => {
    fetchTrainings({ query });
  }, [query]);

  const handleCancelTraining = () => {
    clearModal();
    setTraining(null);
  };

  const handleTrain = () => {
    createTraining();
    modalComponent(
      "Add Training",
      <TrainingForm handleCancel={handleCancelTraining} />
    );
  };

  const renderTrainings = () => {
    if (Array.isArray(trainings)) {
      if (trainings.length === 0) {
        return <p>You don't have any custom trainings yet.</p>;
      }
      const trainingsRender = trainings.sort((a, b) =>
        moment(a.createdAt).isBefore(moment(b.createdAt)) ? 1 : -1
      );
      return trainingsRender.map((training) => (
        <TrainingCard key={training.training_id} training={training} />
      ));
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div id="trainings" className="container-fluid px-0 p-3">
      <div className="row mb-1 align-items-center">
        <div className="col-6 col-md-12 col-xl-6">
          <h3 className="text-capitalize">{title}</h3>
        </div>
        <div className="col-6 col-md-12 col-xl-6 text-end">
          {avatar.user_id === user.user_id && (
            <a
              href={`${API_URL}/files/Sample_Dataset`}
              className="btn px-0 text-primary"
            >
              <i className="fa fa-download me-1"></i> Sample File
            </a>
          )}
        </div>
      </div>
      <input
        type="text"
        value={query}
        className="form-control mb-4"
        placeholder="Search trainings..."
        onChange={(e) => setQuery(e.target.value)}
      />
      <button
        className="btn btn-outline-primary w-100 mb-3"
        onClick={handleTrain}
      >
        <i className="fa fa-plus me-2"></i>
        Training
      </button>
      {renderTrainings()}
    </div>
  );
};

export default TrainingList;
