import React, { useState, useContext, useEffect } from "react";
import { MessagesContext } from "../../context/MessagesContext";
import { ModalContext } from "../../context/ModalContext";
import MessageOutputForm from "./MessageOutputForm";
import parse from "html-react-parser";
import moment from "moment";

const MessageCard = ({ message, prevMessage, handleCallback }) => {
  const { success, clearModal, modalComponent } = useContext(ModalContext);
  const { saveMessage } = useContext(MessagesContext);
  const [editedText, setEditedText] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      success("Copied to clipboard.");
    }
  }, [copied]);

  const handleSave = () => {
    modalComponent(
      "Save Message",
      <MessageOutputForm
        message={message}
        prevMessage={prevMessage}
        handleCancel={clearModal}
      />
    );
  };

  const handleCopy = () => {
    let input = document.createElement("textarea");
    input.value = message.content;
    input.id = "copy-input";
    document.body.appendChild(input);
    var copyText = document.getElementById("copy-input");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    navigator.clipboard.writeText(copyText.value).then(() => {
      setCopied(true);
    });
    input.remove();
  };

  const clearEdit = () => {
    setEditMode(false);
    setEditedText("");
  };

  const handleEdit = () => {
    if (editMode) {
      clearEdit();
      return saveMessage({ ...message, content: editedText }, handleCallback);
    }
    setEditMode(true);
    setEditedText(message.content);
  };

  const renderMessage = () => {
    if (editMode) {
      return (
        <textarea
          rows="10"
          type="text"
          value={editedText}
          className="form-control mb-3"
          onChange={(e) => setEditedText(e.target.value)}
        />
      );
    }
    if (message.content === null) {
      return "Working on this...";
    }
    return parse(String(message.content).replace(/(?:\r\n|\r|\n)/g, "<br>"));
  };

  return (
    <div
      className={`card p-3 mb-3 message-card ${
        message.role === "assistant"
          ? "ms-0 me-auto bg-accent-light"
          : "ms-auto me-0 bg-white"
      }`}
    >
      {renderMessage()}
      <div className="row align-items-center mt-3">
        <div className="col-12 col-md-6 align-items-center">
          <p className="small mb-0 text-muted">
            {moment(message.createdAt).format("MMM Do YYYY HH:mm")}
          </p>
        </div>
        <div className="col-12 col-md-6 text-end">
          {message.role !== "user" && (
            <div>
              {editMode && (
                <button
                  onClick={clearEdit}
                  className="btn border-0 btn-round btn-sm btn-outline-danger me-1"
                >
                  <i className="fa fa-times"></i>
                </button>
              )}
              <button
                onClick={handleEdit}
                className="btn border-0 btn-round btn-sm btn-outline-dark me-1"
              >
                <i className={`fa fa-${editMode ? "save" : "edit"}`}></i>
              </button>
              {!editMode && [
                <button
                  key="copy"
                  onClick={handleCopy}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Copy to clipboard"
                  className="btn border-0 btn-round btn-sm btn-outline-dark me-1"
                >
                  <i className="fa fa-copy"></i>
                </button>,
                <button
                  key="append"
                  onClick={handleSave}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Add To Avatar"
                  className="btn border-0 btn-round btn-sm"
                >
                  <i className="fa fa-user-plus"></i>
                </button>,
              ]}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MessageCard;
