import React, { useState, useContext } from "react";
import { TemplatesContext } from "../../context/TemplatesContext";
import { OutputsContext } from "../../context/OutputsContext";
import { getValue } from "../../utils";
import parse from "html-react-parser";
import TemplateFormSelect from "./TemplateFormSelect";

const TemplateApplyForm = ({ avatar_id, handleCancel, handleApply }) => {
  const [templateFields, setTemplateFields] = useState({});
  const { generateOutput } = useContext(OutputsContext);
  const { template, spinner } = useContext(TemplatesContext);

  const parseTemplateContent = () => {
    let content = template.content;
    String(template.fields)
      .split(",")
      .forEach((key) => {
        let trimKey = String(key).trim();
        trimKey = processFieldName(trimKey);
        if (trimKey.indexOf("[") !== -1) {
          const start = trimKey.indexOf("[") + 1;
          trimKey = trimKey.substring(0, start - 1);
          const keyIndex = key.indexOf("[");
          key = key.substring(0, keyIndex);
        }
        let currentValue = templateFields[trimKey];
        if (!currentValue) {
          currentValue = `<span className='text-primary mx-1'>[${key}]</span>`;
        } else {
          currentValue = `<span className='text-accent mx-1'>${currentValue}</span>`;
        }
        const stringToReplace = `[${key}]`;
        content = String(content).replaceAll(stringToReplace, currentValue);
      });
    return parse(content);
  };

  const processValuesToPrompt = () => {
    let currentPrompt = template.content;
    String(template.fields)
      .split(",")
      .forEach((key) => {
        let trimKey = String(key).trim();
        trimKey = processFieldName(trimKey);
        if (trimKey.indexOf("[") !== -1) {
          const start = trimKey.indexOf("[") + 1;
          trimKey = trimKey.substring(0, start - 1);
          const keyIndex = key.indexOf("[");
          key = key.substring(0, keyIndex);
        }
        let currentValue = templateFields[trimKey];
        const stringToReplace = `[${key}]`;
        currentPrompt = String(currentPrompt).replaceAll(
          stringToReplace,
          currentValue
        );
      });
    return currentPrompt;
  };

  const processFieldName = (field) => {
    field = field.replaceAll(" ", "_");
    field = field.replaceAll(":", "_");
    return field;
  };

  const handleChangeField = (field, value) => {
    let current = { ...templateFields };
    console.log(current);
    current[field] = value;
    setTemplateFields(current);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const prompt = processValuesToPrompt();
    if (typeof handleApply === "function") {
      return handleApply(prompt);
    }
    generateOutput({
      prompt,
      avatar_id,
      maxTokens: 1600,
      temperature: 0.7,
      manual_mode: true,
    });
  };

  const renderInputFields = () => {
    const fieldsArray = String(template.fields).split(",");
    return fieldsArray.map((field) => {
      field = String(field).trim();
      let fieldKey = processFieldName(field);
      if (field.indexOf("[") !== -1) {
        const start = field.indexOf("[") + 1;
        const end = field.indexOf("]");
        const options = field.substring(start, end).split("/");
        if (Array.isArray(options)) {
          const label = field.substring(0, start - 1);
          fieldKey = fieldKey.substring(0, start - 1);
          return (
            <TemplateFormSelect
              modifier={(value) => handleChangeField(fieldKey, value)}
              value={getValue(templateFields, fieldKey)}
              options={options}
              key={fieldKey}
              label={label}
            />
          );
        }
      }
      return (
        <div key={fieldKey}>
          <label className="text-capitalize">{field}</label>
          <input
            type="text"
            className="form-control mb-3"
            value={getValue(templateFields, fieldKey)}
            onChange={(e) => handleChangeField(fieldKey, e.target.value)}
          />
        </div>
      );
    });
  };

  return (
    <form onSubmit={handleSubmit} className="container px-0">
      <p className="text-muted">{parseTemplateContent()}</p>
      {renderInputFields()}
      <div className="row mt-3">
        <div className="col-6">
          <button
            type="submit"
            disabled={spinner}
            className="btn btn-primary w-100"
          >
            {spinner ? <div className="spinner-border"></div> : "Continue"}
          </button>
        </div>
        <div className="col-6">
          <button
            type="button"
            onClick={handleCancel}
            className="btn btn-link w-100 text-muted"
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  );
};

export default TemplateApplyForm;
