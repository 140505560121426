import React, { useState, useContext, useEffect } from "react";
import GeneratorMessageCard from "../components/generator/GeneratorMessageCard";
import GeneratorInput from "../components/generator/GeneratorInput";
import { MessagesContext } from "../context/MessagesContext";
import { setupTooltips } from "../utils";
import moment from "moment";

const Generator = ({ title, message_type_id, base_prompt }) => {
  const [favorites, setFavorites] = useState(false);
  const { messages, generating, getMessages, saveMessage, clearMessages } =
    useContext(MessagesContext);

  useEffect(() => {
    clearMessages();
    fetchMessages();
    return clearMessages;
  }, [message_type_id, favorites]);

  useEffect(() => {
    setupTooltips();
  }, [messages]);

  const getPrompt = (niche) => {
    return `${base_prompt} ${niche}.`;
  };

  const fetchMessages = () => {
    getMessages({ favorite: favorites, message_type_id });
  };

  const handleSubmit = (content) => {
    const prompt = getPrompt(content);
    saveMessage({ content: prompt, message_type_id }, fetchMessages);
  };

  const renderMessages = () => {
    if (Array.isArray(messages)) {
      let messagesRender = [...messages];
      let prevMessage;
      let messagesResult = [];
      messagesRender.forEach((message) => {
        if (message.role === "user") {
          prevMessage = message;
        } else if (prevMessage) {
          message.user_message_id = prevMessage.message_id;
          message.prompt = prevMessage.content;
        }
        if (message.role === "assistant") {
          messagesResult.push(message);
        }
      });
      messagesRender = messagesResult.sort((a, b) =>
        moment(a.createdAt).isAfter(moment(b.createdAt)) ? -1 : 1
      );
      return messagesRender.map((message) => (
        <GeneratorMessageCard
          message={message}
          key={message.message_id}
          handleCallback={fetchMessages}
        />
      ));
    }
  };

  const renderSpinner = () => {
    if (generating) {
      return (
        <div className="my-4 text-center text-large">
          <div className="spinner-border"></div> Generating...
        </div>
      );
    }
  };

  return (
    <div className="container-fluid py-4">
      <div className="row align-items-center">
        <div className="col-10">
          <h1 className="text-gradient d-inline-block">{title}</h1>
        </div>
        <div className="col-2 text-end">
          <button
            onClick={() => setFavorites(!favorites)}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Show favorites only"
            className={`btn btn-sm btn-round border ${
              favorites ? "btn-warning" : ""
            }`}
          >
            <i className="fa fa-star"></i>
          </button>
        </div>
      </div>
      <GeneratorInput
        spinner={generating}
        handleSubmit={handleSubmit}
        placeholder="What is your product or service?"
      />
      {renderSpinner()}
      {renderMessages()}
    </div>
  );
};

export default Generator;
