import React, { createContext, useReducer } from "react";
import AnalyticsReducer from "../reducers/AnalyticsReducer";
import AnalyticsService from "../services/AnalyticsService";
import {
  INSCRITOS_RECIBIDOS,
  INGRESOS_RECBIDOS,
  PAQUETES_RECIBIDOS,
  RESERVATIONS_RECIBIDAS,
  COACHES_RECIBIDAS,
  FREE_TRIALS_RECIBIDAS,
  VIDEOS_RECIBIDOS,
} from "../types/analytics";

const initialState = {
  alumnas: null,
  paquetes: null,
  ingresos: null,
};

export const AnalyticsContext = createContext(initialState);

export const AnalyticsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AnalyticsReducer, initialState);

  const getSignUps = (start_date, end_date) => {
    AnalyticsService.getInscritos(start_date, end_date).then((res) => {
      dispatch({ type: INSCRITOS_RECIBIDOS, payload: res.data });
    });
  };

  const getPaquetes = (start_date, end_date) => {
    AnalyticsService.getPaquetes(start_date, end_date).then((res) => {
      dispatch({ type: PAQUETES_RECIBIDOS, payload: res.data.purchases });
    });
  };

  const getIngresos = (start_date, end_date) => {
    AnalyticsService.getIngresos(start_date, end_date).then((res) => {
      dispatch({ type: INGRESOS_RECBIDOS, payload: res.data });
    });
  };

  const getReservaciones = (start_date, end_date) => {
    AnalyticsService.getReservaciones(start_date, end_date).then((res) => {
      dispatch({
        type: RESERVATIONS_RECIBIDAS,
        payload: {
          reservaciones: res.data.reservations,
          horas: res.data.capacity,
          days: res.data.days,
        },
      });
    });
  };

  const getInstructores = (start_date, end_date) => {
    AnalyticsService.getInstructores(start_date, end_date).then((res) => {
      const { result } = res.data;
      dispatch({ type: COACHES_RECIBIDAS, payload: result });
    });
  };

  const getFreeTrials = (start_date, end_date) => {
    AnalyticsService.getFreeTrials(start_date, end_date).then((res) => {
      const { purchases } = res.data;
      dispatch({ type: FREE_TRIALS_RECIBIDAS, payload: purchases });
    });
  };

  const getVideos = (fechaInicio, fechaFin) => {
    AnalyticsService.getVideos(fechaInicio, fechaFin).then((res) => {
      dispatch({ type: VIDEOS_RECIBIDOS, payload: res.data });
    });
  };

  const clearInstructores = () => {
    dispatch({ type: COACHES_RECIBIDAS, payload: null });
  };

  return (
    <AnalyticsContext.Provider
      value={{
        ...state,
        getVideos,
        getPaquetes,
        getIngresos,
        getSignUps,
        getFreeTrials,
        getInstructores,
        getReservaciones,
        clearInstructores,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
