import React, { useEffect, useContext } from "react";
import moment from "moment";
import Login from "./auth/Login";
import SignUp from "./auth/SignUp";
import Recovery from "./auth/Recovery";
import { Router } from "@reach/router";

const Auth = () => {
  return (
    <div id="particles-container" className="bg-dark container-fluid px-0">
      <div className="auth container px-0">
        <Router>
          <Login path="/login" isHome default />
          <SignUp path="/signup" isHome />
          <Recovery path="/recovery" isHome />
        </Router>
      </div>
    </div>
  );
};

export default Auth;
