import api from "./api";

const route = "/conversations";

const ConversationsService = {
  getConversations: () => api.get(route),
  getSingleConversations: (conversation_id) =>
    api.get(`${route}/${conversation_id}`),
  postConversation: (conversation) => api.post(route, { ...conversation }),
  putConversation: (conversation) => api.put(route, { ...conversation }),
  deleteConversation: (conversation_id) =>
    api.delete(`${route}/${conversation_id}`),
};
export default ConversationsService;
