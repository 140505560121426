import React, { useEffect } from "react";

const TemplateFormSelect = ({ label, value, options, modifier }) => {
  useEffect(() => {
    if (
      (!value || value === null || value === "undefined") &&
      Array.isArray(options) &&
      options.length > 0
    ) {
      modifier(options[0]);
    }
  }, []);

  return (
    <div className="mb-3">
      <label className="text-capitalize">{label}</label>
      <select
        className="form-control"
        value={value}
        onChange={(e) => modifier(e.target.value)}
      >
        {[
          <option key="">Select an option...</option>,
          ...options.map((value) => [
            <option key={value} value={value}>
              {value}
            </option>,
          ]),
        ]}
      </select>
    </div>
  );
};

export default TemplateFormSelect;
