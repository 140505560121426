import React, { useReducer, createContext, useContext } from "react";
import MessagesReducer from "../reducers/MessagesReducer";
import {
  SET_PROPERTY_MESSAGE,
  MESSAGES_RECEIVED,
  CREATE_MESSAGE,
  APPEND_MESSAGE,
  SET_MESSAGE,
  CLEAR_MESSAGES,
  GENERATING_MESSAGE,
  ENHANCED_RECEIVED,
  SET_ENHANCING,
  SET_PROMPT,
  SET_PROPERTY_MESSAGE_PLURAL,
  SAVE_MESSAGE,
  APPEND_ENHANCED_TOKEN,
} from "../types/messages";
import MessagesService from "../services/MessagesService";
import { ModalContext } from "./ModalContext";
import { navigate } from "@reach/router";
import { HIDE_SPINNER } from "../types";

const initialState = {
  generating: false,
  enhancing: false,
  message_id: null,
  spinner: false,
  messages: null,
  message: null,
  prompt: "",
  max: null,
};

export const MessagesContext = createContext(initialState);

export const MessagesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(MessagesReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const handleError = (error) => {
    dispatch({ type: HIDE_SPINNER });
    if (error.response) {
      if (error.response.data) {
        if (error.response.data.error) {
          if (error.response.data.error.code) {
            return alert(`Open AI Error: ${error.response.data.error.message}`);
          }
        }
      }
      if (error.response.status === 412) {
        clearModal();
        return navigate("/sorry");
      }
    }
    alert(error);
  };

  const getMessages = (filters) => {
    MessagesService.getMessages(filters).then((res) => {
      const { messages, max } = res.data;
      dispatch({ type: MESSAGES_RECEIVED, payload: { messages, max } });
    });
  };

  const getConversationMessages = (conversation_id, filters) => {
    MessagesService.getConversationMessages(conversation_id, filters).then(
      (res) => {
        const { messages, max } = res.data;
        dispatch({ type: MESSAGES_RECEIVED, payload: { messages, max } });
      }
    );
  };

  const appendMessage = (message) => {
    dispatch({ type: APPEND_MESSAGE, payload: message });
  };

  const setEnhanced = (message) => {
    dispatch({ type: ENHANCED_RECEIVED, payload: message });
  };

  const setEnhancing = (payload) => {
    dispatch({ type: SET_ENHANCING, payload });
  };

  const saveMessage = (message, callback) => {
    let service = MessagesService.putMessage;
    if (isNaN(parseInt(message.message_id))) {
      service = MessagesService.postMessage;
      dispatch({ type: GENERATING_MESSAGE });
    }
    service(message)
      .then((res) => {
        if (res.data.message) {
          console.log(res.data.message);
          dispatch({ type: APPEND_MESSAGE, payload: res.data.message });
        }
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch(handleError);
  };

  const createMessage = () => {
    dispatch({ type: CREATE_MESSAGE });
  };

  const setMessage = (message) => {
    dispatch({ type: SET_MESSAGE, payload: message });
  };

  const setPropertyMessage = (key, value) => {
    dispatch({ type: SET_PROPERTY_MESSAGE, payload: { key, value } });
  };

  const setPropertyMessagePlural = (message_id, key, value) => {
    dispatch({
      type: SET_PROPERTY_MESSAGE_PLURAL,
      payload: { message_id, key, value },
    });
  };

  const appendEnhancedToken = (value) => {
    dispatch({
      type: APPEND_ENHANCED_TOKEN,
      payload: value,
    });
  };

  const deleteMessage = (message_id) => {
    MessagesService.deleteMessage(message_id)
      .then(() => {
        success("Message deleted.");
      })
      .catch(handleError);
  };

  const clearMessages = () => {
    dispatch({ type: CLEAR_MESSAGES });
  };

  const setPrompt = (prompt) => {
    dispatch({ type: SET_PROMPT, payload: prompt });
  };

  const saveMessageContent = (message_id) => {
    dispatch({ type: SAVE_MESSAGE, payload: message_id });
  };

  return (
    <MessagesContext.Provider
      value={{
        ...state,
        setPrompt,
        setMessage,
        saveMessage,
        getMessages,
        setEnhanced,
        setEnhancing,
        clearMessages,
        createMessage,
        deleteMessage,
        appendMessage,
        setPropertyMessage,
        saveMessageContent,
        appendEnhancedToken,
        getConversationMessages,
        setPropertyMessagePlural,
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
};
