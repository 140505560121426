import React, { useRef, useState, useContext, useEffect } from "react";
import { ConversationsContext } from "../context/ConversationsContext";
import { MessagesContext } from "../context/MessagesContext";
import MessageCard from "../components/messages/MessageCard";
import { AuthContext } from "../context/AuthContext";
import Upgrade from "./Upgrade";
import ConversationHeader from "../components/conversations/ConversationHeader";
import MessageInputForm from "../components/messages/MessageInputForm";
import { setupTooltips } from "../utils";
import { TemplatesContext } from "../context/TemplatesContext";

const SingleConversation = ({ conversation_id }) => {
  const refContainer = useRef(null);

  const [page, setPage] = useState(1);
  const [firstLoad, setFirstLoad] = useState(true);

  const { user } = useContext(AuthContext);
  const { template } = useContext(TemplatesContext);
  const { getSingleConversation } = useContext(ConversationsContext);
  const {
    max,
    messages,
    setPrompt,
    message_id,
    generating,
    saveMessage,
    clearMessages,
    getConversationMessages,
  } = useContext(MessagesContext);

  useEffect(() => {
    clearMessages();
    if (!template || template === null) {
      setPrompt("");
    }
  }, [conversation_id]);

  useEffect(() => {
    setupTooltips();
    if (Array.isArray(messages)) {
      handleScrollBottom();
    }
  }, [messages]);

  useEffect(() => {
    fetchMessages();
    getSingleConversation(conversation_id);
  }, [conversation_id, page]);

  useEffect(() => {
    if (message_id && message_id !== null) {
      if (Array.isArray(messages)) {
        const current = messages.find(
          (message) => parseInt(message.message_id) === parseInt(message_id)
        );
        if (current && current !== null) saveMessage(current);
      }
    }
  }, [message_id]);

  const fetchMessages = () => {
    getConversationMessages(conversation_id, { page });
  };

  const handleScrollBottom = () => {
    const container = refContainer.current;
    refContainer.current.scrollTo({
      top: firstLoad
        ? container.offsetHeight * 10
        : container.offsetHeight * messages.length * 2,
    });
    if (firstLoad) setFirstLoad(false);
  };

  const handleScroll = () => {
    const container = refContainer.current;
    const scrollTop = container.scrollTop;
    if (scrollTop === 0 && Array.isArray(messages)) {
      if (messages.length < max) {
        setPage(page + 1);
      }
    }
  };

  const handleMessage = (prompt) => {
    saveMessage(
      {
        content: prompt,
        conversation_id,
        stream: true,
      },
      fetchMessages
    );
  };

  const renderMax = () => {
    if (Array.isArray(messages)) {
      if (messages.length >= max) {
        return (
          <div className="d-flex mt-3 justify-content-center">
            <span className="border bg-accent badge badge-pill mb-3 m-auto d-inline-block">
              No more messages
            </span>
          </div>
        );
      }
    }
  };

  const renderMessages = () => {
    if (Array.isArray(messages)) {
      return messages.map((message, index) => (
        <MessageCard
          message={message}
          key={message.message_id}
          handleCallback={fetchMessages}
          prevMessage={messages[index - 1]}
        />
      ));
    }
  };

  const renderSpinner = () => {
    if (generating) {
      return <div className="spinner-border"></div>;
    }
  };

  const renderContent = () => {
    if (!user.has_access) {
      return (
        <Upgrade>
          <p>
            Creating more content with{" "}
            <span className="text-accent">
              Bemodo's AI Content Assitant "Fetch"
            </span>{" "}
            requires an active subscription to BemodoAI.
          </p>
          <p>
            Click the button to subscribe now and use{" "}
            <span className="badge bg-accent mx-2 p-2">WELCOME</span> discount
            code to get 14 days of access for only $4.95 USD
          </p>
          <p>Then, $29.95 per month.</p>
        </Upgrade>
      );
    }
    return (
      <div>
        <ConversationHeader />
        <div className="row">
          <div
            id="messages"
            className="px-3 pb-5"
            ref={refContainer}
            onScroll={handleScroll}
          >
            {renderMax()}
            {renderMessages()}
            {renderSpinner()}
          </div>
        </div>
        <MessageInputForm spinner={generating} handleSubmit={handleMessage} />
      </div>
    );
  };

  return (
    <div id="conversation" className="container-fluid">
      {renderContent()}
    </div>
  );
};

export default SingleConversation;
