import React, { useContext } from "react";
import { TemplatesContext } from "../context/TemplatesContext";
import TemplateList from "../components/templates/TemplatesList";

const PublicTemplates = ({ avatar_id, hideEdit, hideCreate, handleApply }) => {
  const { templates, getPublicTemplates } = useContext(TemplatesContext);

  const fetchTemplates = (params) => {
    getPublicTemplates(params);
  };

  return (
    <div className="container-fluid px-0">
      <TemplateList
        title="Templates"
        hideEdit={hideEdit}
        avatar_id={avatar_id}
        templates={templates}
        hideCreate={hideCreate}
        handleApply={handleApply}
        fetchTemplates={fetchTemplates}
      />
    </div>
  );
};

export default PublicTemplates;
