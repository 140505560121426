import React, { useContext } from "react";
import DynamicForm from "../global/DynamicForm";
import { ConversationsContext } from "../../context/ConversationsContext";

const questions = [
  {
    id: "name",
    type: "text",
    label: "Name",
    required: true,
  },
];

const ConversationForm = ({ handleCancel }) => {
  const { conversation, saveConversation, setPropertyConversation } =
    useContext(ConversationsContext);
  return (
    <DynamicForm
      object={conversation}
      questions={questions}
      handleCancel={handleCancel}
      saveAction={saveConversation}
      modifier={setPropertyConversation}
    />
  );
};

export default ConversationForm;
