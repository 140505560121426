import moment from "moment";
import {
  MESSAGES_RECEIVED,
  CREATE_MESSAGE,
  SET_MESSAGE,
  SET_PROPERTY_MESSAGE,
  APPEND_MESSAGE,
  CLEAR_MESSAGES,
  GENERATING_MESSAGE,
  ENHANCED_RECEIVED,
  SET_ENHANCING,
  SET_PROMPT,
  SET_PROPERTY_MESSAGE_PLURAL,
  SAVE_MESSAGE,
  APPEND_ENHANCED_TOKEN,
} from "../types/messages";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const schema = {
  message_id: "",
  name: "",
};

const MessagesReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_PROMPT:
      return { ...state, prompt: payload };
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    case SET_ENHANCING:
      return {
        ...state,
        enhancing: payload,
        enhanced: payload ? null : state.enhanced,
      };
    case ENHANCED_RECEIVED:
      return { ...state, enhanced: payload };
    case GENERATING_MESSAGE:
      return { ...state, generating: true };
    case CLEAR_MESSAGES:
      return { ...state, messages: null };
    case SAVE_MESSAGE: {
      return { ...state, message_id: payload };
    }
    case APPEND_ENHANCED_TOKEN: {
      const value = payload;
      let enhanced = String(state.enhanced);

      if (
        enhanced === null ||
        enhanced === "undefined" ||
        String(enhanced) === "null"
      ) {
        enhanced = value;
      } else {
        enhanced += value;
      }

      return { ...state, enhanced };
    }
    case APPEND_MESSAGE: {
      if (state.enhancing || (state.enhanced && state.enhanced !== null)) {
        return { ...state };
      }
      let messages = state.messages;
      if (Array.isArray(messages)) messages = [...messages];
      else messages = [];
      const messageExists =
        messages.find(
          (message) =>
            parseInt(message.message_id) === parseInt(payload.message_id)
        ) !== undefined;
      if (messageExists) {
        return { ...state, messages, enhancing: false, enhanced: null };
      }
      messages.push(payload);
      messages = messages.sort((a, b) =>
        moment(a.createdAt).isAfter(moment(b.createdAt)) ? 1 : -1
      );
      return {
        ...state,
        messages,
        enhanced: null,
        enhancing: false,
        generating: payload.role !== "assistant",
      };
    }
    case MESSAGES_RECEIVED: {
      let messages = state.messages;
      if (Array.isArray(messages)) messages = [...messages];
      else messages = [];
      messages = [...payload.messages, ...messages];
      let messagesSet = new Set();
      messages.forEach(({ message_id }) => messagesSet.add(message_id));
      let messagesResult = [];
      messagesSet.forEach((message_id) => {
        let current = messages.find(
          (message) => message.message_id === message_id
        );
        if (current) messagesResult.push(current);
      });
      messages = messagesResult.sort((a, b) =>
        moment(a.createdAt).isAfter(b.createdAt) ? 1 : -1
      );
      return { ...state, messages, max: payload.max };
    }
    case SET_MESSAGE:
      return { ...state, message: payload };
    case CREATE_MESSAGE:
      return { ...state, message: schema };
    case SET_PROPERTY_MESSAGE:
      const { key, value } = payload;
      const message = { ...state.message };
      message[key] = value;
      return { ...state, message };
    case SET_PROPERTY_MESSAGE_PLURAL: {
      const { message_id, key, value } = payload;
      let messages = state.messages;
      if (Array.isArray(messages)) messages = [...messages];
      else messages = [];
      const index = messages.findIndex(
        (message) => parseInt(message.message_id) === parseInt(message_id)
      );
      if (index !== -1) {
        if (messages[index][key] === null) {
          messages[index][key] = value;
        } else {
          messages[index][key] += value;
        }
      }
      return { ...state, messages };
    }
    default:
      return { ...state };
  }
};
export default MessagesReducer;
