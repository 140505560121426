import React, { useContext, useEffect, useState } from "react";
import PurchasesTable from "../../components/purchases/PurchasesTable";
import PanelTitleDate from "../../components/global/PanelTitleDate";
import { PurchasesContext } from "../../context/PurchasesContext";
import { ProductsContext } from "../../context/ProductsContext";
import Pagination from "../../components/global/Pagination";
import { AuthContext } from "../../context/AuthContext";

const AdminPurchases = () => {
  const [page, setPage] = useState(1);
  const [type, setType] = useState(null);
  const [query, setQuery] = useState("");
  const [status, setStatus] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [productId, setProductId] = useState(null);

  const { spinner, purchases, getPurchasesAdmin } =
    useContext(PurchasesContext);

  const { products, getProducts } = useContext(ProductsContext);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    if (startDate !== "" && endDate !== "" && !spinner) {
      fetchPurchases();
    }
  }, [startDate, endDate, productId, page, status, query]);

  const fetchPurchases = () => {
    getPurchasesAdmin({
      page,
      query,
      status,
      product_id: productId,
      start_date: startDate,
      end_date: endDate,
    });
  };

  const setDates = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const filterPurchases = () => {
    let result = purchases;
    if (Array.isArray(purchases)) {
      switch (type) {
        case "purchase":
          result = purchases.filter((purchase) => !purchase.invoice_id);
          break;
        case "invoice":
          result = purchases.filter((purchase) => purchase.invoice_id);
          break;
      }
    }
    return result;
  };

  const renderProducts = () => {
    if (Array.isArray(products)) {
      return [
        <option value="">All Products</option>,
        ...products.map((product) => (
          <option key={product.product_id} value={product.product_id}>
            {product.name}
          </option>
        )),
      ];
    }
  };

  return (
    <div className="container-fluid">
      <PanelTitleDate title="Purchases" callback={setDates} />
      <div className="container-fluid px-0">
        <input
          type="text"
          value={query}
          className="form-control mb-3"
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Search..."
        />
      </div>
      <div className="card no-scale p-3 mb-3 shadow-sm">
        <div className="row align-items-center mb-3">
          <div className="col-12 col-md-6">
            <button
              className={`btn btn-sm btn-${
                status === "" ? "primary" : "light"
              } br-0`}
              onClick={() => setStatus("")}
            >
              All
            </button>
            <button
              className={`btn btn-sm btn-${
                Array.isArray(status) && status.includes("active")
                  ? "primary"
                  : "light"
              } br-0`}
              onClick={() => setStatus(["active", "completed", "success"])}
            >
              Successful
            </button>
            <button
              className={`btn btn-sm btn-${
                Array.isArray(status) && status.includes("cancelled")
                  ? "primary"
                  : "light"
              } br-0`}
              onClick={() => setStatus(["cancelled", "failed"])}
            >
              Failed
            </button>
            <button
              className={`btn btn-sm btn-${
                Array.isArray(status) && status.includes("processing")
                  ? "primary"
                  : "light"
              } br-0`}
              onClick={() => setStatus(["pending", "processing", "on-hold"])}
            >
              Pending
            </button>
          </div>
          <div className="col-12 col-md-3">
            <button
              className={`btn btn-sm btn-${
                type === null ? "primary" : "light"
              } br-0`}
              onClick={() => setType(null)}
            >
              All
            </button>
            <button
              className={`btn btn-sm btn-${
                type === "purchase" ? "primary" : "light"
              } br-0`}
              onClick={() => setType("purchase")}
            >
              Purchases
            </button>
            <button
              className={`btn btn-sm btn-${
                type === "invoice" ? "primary" : "light"
              } br-0`}
              onClick={() => setType("invoice")}
            >
              Invoices
            </button>
          </div>
          <div className="col-12 col-md-3">
            <select
              value={productId}
              className="form-control"
              onChange={(e) => setProductId(e.target.value)}
            >
              {renderProducts()}
            </select>
          </div>
        </div>
        <PurchasesTable
          showCustomer
          purchases={filterPurchases()}
          user={user}
          showType
        />
        <Pagination modifier={setPage} />
      </div>
    </div>
  );
};

export default AdminPurchases;
