import React, { useState, useContext } from "react";
import StatusBadge from "../global/StatusBadge";
import { TrainingsContext } from "../../context/TrainingsContext";
import { AvatarsContext } from "../../context/AvatarsContext";
import { ModalContext } from "../../context/ModalContext";
import { navigate } from "@reach/router";
import { API_URL } from "../../utils";
import moment from "moment";
import TrainingForm from "./TrainingForm";

const TrainingCard = ({ training }) => {
  const { avatar, saveAvatar, getSingleAvatar } = useContext(AvatarsContext);
  const { setTraining, syncTraining, deleteTraining, executeTraining } =
    useContext(TrainingsContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  const handleCallback = () => getSingleAvatar(training.avatar_id);

  const handleCancel = () => {
    setTraining(null);
    clearModal();
  };

  const handleEdit = () => {
    setTraining(training);
    modalComponent(
      "Edit Training",
      <TrainingForm handleCancel={handleCancel} />
    );
  };

  const handleTraining = () => {
    modalComponent(
      "Begin Training",
      <div>
        <p>
          This will execute a training on this dataset and you will be billed to
          your OpenAI account between $20 to $60 USD depending on the size of
          your dataset.
        </p>
        <div className="row">
          <div className="col-6">
            <button
              onClick={() => {
                executeTraining(training);
                clearModal();
              }}
              className="btn btn-primary w-100"
            >
              Train
            </button>
          </div>
          <div className="col-6">
            <button onClick={clearModal} className="btn text-muted w-100">
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  const handleDelete = () => {
    modalComponent(
      "Delete Training",
      <div>
        <p>Are you sure you want to delete the training {training.name}?</p>
        <p>This action can NOT be reversed.</p>
        <div className="row">
          <div className="col-6">
            <button
              className="btn btn-danger"
              onClick={() => deleteTraining(training, handleCallback)}
            >
              <i className="fa fa-trash me-2"></i>Delete Training
            </button>
          </div>
          <div className="col-6 text-end">
            <button className="btn btn-link text-muted" onClick={clearModal}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  const setDefaultTraining = () => {
    avatar.selected_training = training.training_id;
    saveAvatar(avatar);
  };

  return (
    <div className="card bg-white pb-2 mb-3">
      <div className="card-header pb-0 pt-3 bg-white border-0">
        <div className="row mb-3 align-items-center">
          <div className="col-8 col-md-12 col-xl-8">
            <h3 className="h5 bold small">{training.name}</h3>
          </div>
          <div className="col-4 col-md-12 col-xl-4 text-xl-end">
            <StatusBadge status={training.status} />
          </div>
        </div>
      </div>
      <div className="card-body py-0 bg-white border-0">
        <p className="mb-0">{training.fine_tuned_model}</p>
        <p className="small text-muted mb-0">
          Last Updated: {moment(training.updatedAt).format("MMM Do YYYY HH:mm")}
        </p>
      </div>
      <div className="card-footer bg-white border-0">
        {training.status === "succeeded" && (
          <button
            onClick={setDefaultTraining}
            className={`btn btn-sm ${
              avatar.selected_training === training.training_id
                ? "btn-warning"
                : ""
            } px-2 me-2 border`}
          >
            <i
              className={
                avatar.selected_training === training.training_id
                  ? "fa fa-star text-dark"
                  : "far fa-star"
              }
            ></i>
          </button>
        )}
        <button
          onClick={handleEdit}
          className="btn btn-sm btn-round border me-2"
        >
          <i className="fa fa-edit"></i>
        </button>
        <button
          onClick={handleDelete}
          className="btn btn-round btn-sm btn-outline-danger px-2 me-2"
        >
          <i className="fa fa-trash"></i>
        </button>
        {training.status !== "succeeded" && training.fine_tune_id !== null && (
          <button
            onClick={() => syncTraining(training, handleCallback)}
            className="btn btn-sm btn-outline-dark px-2 me-2"
          >
            <i className="fa fa-sync"></i>
          </button>
        )}
        {training.fine_tune_id === null && (
          <button
            onClick={handleTraining}
            className="btn btn-round btn-sm btn-outline-accent px-2 me-2"
          >
            <i className="fa fa-dumbbell"></i>
          </button>
        )}
        {training.file_id !== null && (
          <a
            href={`${API_URL}/files/${training.file_id}`}
            className="btn btn-round btn-sm text-dark btn-outline-dark me-2"
          >
            <i className="fa fa-download"></i>
          </a>
        )}
        {training.status === "succeeded" && (
          <div className="d-inline-block">
            <button
              className="btn btn-round btn-sm btn-primary hide-mobile"
              onClick={() => {
                setTraining(training);
              }}
            >
              <i className="fa fa-chevron-right mx-1"></i>
            </button>
            <button
              className="btn btn-round btn-sm btn-primary show-mobile"
              onClick={() => {
                setTraining(training);
                navigate(`/avatars/${training.avatar_id}/outputs`);
              }}
            >
              <i className="fa fa-chevron-right mx-1"></i>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TrainingCard;
