import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import { navigate } from "@reach/router";
import React from "react";

const SingleAvatarTabs = ({ avatar }) => {
  const value = window.location.pathname.split(
    `/avatars/${avatar.avatar_id}`
  )[1];
  return (
    <div className="row">
      <IonSegment value={value} className="show-mobile">
        <IonSegmentButton
          value=""
          onClick={() => navigate(`/avatars/${avatar.avatar_id}`)}
        >
          <IonLabel>Trainings</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton
          value="/outputs"
          onClick={() => navigate(`/avatars/${avatar.avatar_id}/outputs`)}
        >
          <IonLabel>Outputs</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton
          value="/details"
          onClick={() => navigate(`/avatars/${avatar.avatar_id}/details`)}
        >
          <IonLabel>Details</IonLabel>
        </IonSegmentButton>
      </IonSegment>
    </div>
  );
};

export default SingleAvatarTabs;
