import React from "react";

const OutputSelectForm = ({ handleSelect, handleCancel }) => {
  return (
    <div>
      <div
        className="card bg-light hover-success cursor-pointer p-3 mb-2"
        onClick={() => handleSelect("manual")}
      >
        <h4>Manual Mode</h4>
        <p>
          Write your own prompt and set parameters such as temperature and max
          tokens.
        </p>
      </div>
      <div
        className="card bg-light hover-success cursor-pointer p-3 mb-2"
        onClick={() => handleSelect("template")}
      >
        <h4>Use a Template</h4>
        <p>Fill in the blanks of a tested and working prompt.</p>
      </div>
      <button className="btn text-muted w-100 my-3" onClick={handleCancel}>
        Cancel
      </button>
    </div>
  );
};

export default OutputSelectForm;
