import api from "./api";

const route = "/invoices";

const InvoicesService = {
  getInvoices: () => api.get(route),
  postInvoice: (project_id, invoice) =>
    api.post(`${route}/${project_id}`, { ...invoice }),
  putInvoice: (project_id, invoice) =>
    api.put(`${route}/${project_id}`, { ...invoice }),
  deleteInvoice: (project_id, invoice_id) =>
    api.put(`${route}/${project_id}/${invoice_id}`),
};

export default InvoicesService;
