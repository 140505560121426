import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import {
  SET_TEMPLATE,
  CREATE_TEMPLATE,
  TEMPLATES_RECEIVED,
  SET_PROPERTY_TEMPLATE,
} from "../types/templates";

const schema = {};

const templatesReducer = (state, { type, payload }) => {
  switch (type) {
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    case TEMPLATES_RECEIVED:
      return { ...state, templates: payload };
    case SET_TEMPLATE:
      return { ...state, template: payload };
    case CREATE_TEMPLATE:
      return { ...state, template: schema };
    case SET_PROPERTY_TEMPLATE: {
      const { key, value } = payload;
      const template = { ...state.template };
      template[key] = value;
      return { ...state, template };
    }
    default:
      return { ...state };
  }
};

export default templatesReducer;
