import { Link } from "@reach/router";
import React, { useContext } from "react";
import bunny from "../../assets/bunny.png";
import { formatMonto, getValue } from "../../utils";
import DarkModeSwitch from "../common/DarkModeSwitch";
import { AuthContext } from "../../context/AuthContext";
import { AppConfigContext } from "../../context/AppConfigContext";

const Sidebar = () => {
  const appconfig = useContext(AppConfigContext);
  const { user, signOut, updateUser } = useContext(AuthContext);

  return (
    <div className="sidebar py-4 px-3 bg-white">
      <img
        src={
          getValue(user, "dark_mode", true)
            ? getValue(appconfig, "dark_logo")
            : getValue(appconfig, "light_logo")
        }
        style={{ maxWidth: 120 }}
        className="m-auto d-block w-100"
        alt="logo"
      />
      <div className="sidebar-menu">
        <div className="px-3">
          <div className="text-center pt-2 border br-10 mb-3">
            <h4>Words</h4>
            <p className="mb-2">
              {user.available_words === 1000000 ? (
                <span>
                  <i className="fa fa-infinity me-2"></i>
                  Unlimited
                </span>
              ) : (
                formatMonto(user.available_words)
              )}
            </p>
          </div>
        </div>

        <Link
          to="/"
          className="btn small w-100 my-2 text-dark hover-success text-left"
        >
          <div className="row">
            <div className="col-2">
              <img
                src={
                  getValue(user, "dark_mode", true)
                    ? getValue(appconfig, "dark_logo")
                    : getValue(appconfig, "light_logo")
                }
                alt="Fetch"
                style={{ maxWidth: 25 }}
                className="d-inline-block"
              />
            </div>
            <div className="col-10">Fetch</div>
          </div>
        </Link>
        <Link
          to="/templates  "
          className="btn small w-100 my-2 text-dark hover-success text-left"
        >
          <div className="row">
            <div className="col-2">
              <i className="fa fa-shapes"></i>
            </div>
            <div className="col-10">Templates</div>
          </div>
        </Link>
        <Link
          to="/avatars"
          className="btn small w-100 my-2 text-dark hover-success text-left"
        >
          <div className="row">
            <div className="col-2">
              <i className="fa fa-user me-2"></i>
            </div>
            <div className="col-10">Avatars</div>
          </div>
        </Link>
        <Link
          to="/hashtags"
          className="btn small w-100 my-2 text-dark hover-success text-left"
        >
          <div className="row">
            <div className="col-2">
              <i className="fa fa-hashtag me-2"></i>
            </div>
            <div className="col-10">Hashtags</div>
          </div>
        </Link>
        <Link
          to="/topics"
          className="btn small w-100 my-2 text-dark hover-success text-left"
        >
          <div className="row">
            <div className="col-2">
              <i className="fa fa-lightbulb me-2"></i>
            </div>
            <div className="col-10">Topics</div>
          </div>
        </Link>
        <Link
          to="/tutorials"
          className="btn small w-100 my-2 text-dark hover-success text-left"
        >
          <div className="row">
            <div className="col-2">
              <i className="fa fa-chalkboard me-2"></i>
            </div>
            <div className="col-10">Tutorials</div>
          </div>
        </Link>
        {!user.has_access && (
          <Link
            to="/pricing"
            className="btn small w-100 my-2 text-dark hover-success text-left"
          >
            <div className="row">
              <div className="col-2">
                <i className="fa fa-star me-2"></i>
              </div>
              <div className="col-10">Upgrade</div>
            </div>
          </Link>
        )}
        {user.user_id === 1 && (
          <Link
            to="/admin"
            className="btn small w-100 my-2 text-dark hover-success text-left"
          >
            <div className="row">
              <div className="col-2">
                <i className="fa fa-user-shield me-2"></i>
              </div>
              <div className="col-10">Admin</div>
            </div>
          </Link>
        )}
        <Link
          to="/billing"
          className="btn small w-100 my-2 text-dark hover-success text-left"
        >
          <div className="row">
            <div className="col-2">
              <i className="fa fa-file-invoice me-2"></i>
            </div>
            <div className="col-10">Billing</div>
          </div>
        </Link>
        <button
          id="navbarDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          className="btn small mt-2 w-100 text-left px-3 dropdown-toggle"
        >
          <i className="fa fa-user me-1"></i>
          <span className="mx-3 small">{user.name}</span>
        </button>
        <ul
          className="dropdown-menu bg-white border"
          aria-labelledby="navbarDropdown"
        >
          <li className="my-1">
            <Link
              to="/settings"
              className="dropdown-item hover-success text-dark"
            >
              <div className="row">
                <div className="col-2">
                  <i className="ms-2 fa fa-cog"></i>
                </div>
                <div className="col-10">Settings</div>
              </div>
            </Link>
          </li>
          <li className="my-1">
            <button
              className="dropdown-item w-100 btn-sm hover-danger text-danger"
              onClick={signOut}
            >
              <div className="row">
                <div className="col-2">
                  <i className="ms-2 fa fa-sign-out-alt"></i>
                </div>
                <div className="col-10">Log Out</div>
              </div>
            </button>
          </li>
        </ul>
      </div>
      <div className="px-3 my-3">
        <DarkModeSwitch
          darkMode={user.dark_mode}
          setDarkMode={(darkMode) => {
            updateUser({ ...user, dark_mode: darkMode }, null, true);
          }}
        />
      </div>
    </div>
  );
};

export default Sidebar;
