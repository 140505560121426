import { Router } from "@reach/router";
import React from "react";
import AdminPurchases from "./AdminPurchases";
import AdminSignUps from "./AdminSignUps";
import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import { navigate } from "@reach/router";

const Admin = () => {
  return (
    <div>
      <IonSegment value={window.location.pathname} className="mb-3">
        <IonSegmentButton value="/admin" onClick={() => navigate("/admin")}>
          <IonLabel>Purchases</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton
          value="/admin/users"
          onClick={() => navigate("/admin/users")}
        >
          <IonLabel>Users</IonLabel>
        </IonSegmentButton>
      </IonSegment>
      <Router>
        <AdminPurchases path="/" default />
        <AdminSignUps path="/users" />
      </Router>
    </div>
  );
};

export default Admin;
