import React from "react";
import { token_options } from "../../utils";

const AutomaticOutputForm = ({
  setPrompt,
  temperature,
  setMaxTokens,
  setTemperature,
}) => {
  return (
    <div>
      <label>Select the type of content</label>
      <select
        className="form-control mb-3"
        onChange={(e) => {
          let valueSplit = String(e.target.value).split("-");
          let tokens = valueSplit[0];
          let content = valueSplit[1];
          setMaxTokens(tokens);
          setPrompt(content);
        }}
      >
        {token_options.map(({ value, label }) => (
          <option key={value} value={`${value}-${label}`}>
            {label}
          </option>
        ))}
      </select>
      <label>How close to your style should they be?</label>
      <select
        value={temperature}
        className="form-control mb-3"
        onChange={(e) => setTemperature(e.target.value)}
      >
        <option value="0.5">Very similar to Original</option>
        <option value="0.7">Similar to Original</option>
        <option value="0.9">Creative</option>
      </select>
    </div>
  );
};

export default AutomaticOutputForm;
