import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import { navigate } from "@reach/router";
import React from "react";

const AvatarTabs = () => {
  return (
    <IonSegment value={window.location.pathname} className="mb-3 show-mobile">
      <IonSegmentButton value="/" onClick={() => navigate("/")}>
        <IonLabel>My Avatars</IonLabel>
      </IonSegmentButton>
      <IonSegmentButton
        value="/community"
        onClick={() => navigate("/community")}
      >
        <IonLabel>Community</IonLabel>
      </IonSegmentButton>
    </IonSegment>
  );
};

export default AvatarTabs;
