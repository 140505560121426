import React, { useState, useEffect, useContext } from "react";
import { OutputsContext } from "../../context/OutputsContext";
import ReactSwitch from "react-switch";
import * as XLSX from "xlsx";
import SelectSheet from "../global/SelectSheet";

const SingleOutputForm = ({ avatar_id, handleCancel, handleCallback }) => {
  const [file, setFile] = useState(null);
  const [bulk, setBulk] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [samples, setSamples] = useState(null);
  const [content, setContent] = useState("");
  const [workbook, setWorkbook] = useState(null);
  const [sheetName, setSheetName] = useState("");
  const [sheetNames, setSheetNames] = useState([]);

  const { postOutput } = useContext(OutputsContext);

  useEffect(() => {
    if (file !== null) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (e) => {
        processFile(e.target.result);
      };
    }
  }, [file]);

  useEffect(() => {
    if (sheetName !== "") readSheet(sheetName);
  }, [sheetName]);

  const processFile = (bufferArray) => {
    const current = XLSX.read(bufferArray, { type: "buffer" });
    setSheetNames(current.SheetNames);
    setWorkbook(current);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (bulk && Array.isArray(samples)) {
      samples.forEach(({ prompt, completion }) =>
        postOutput({ prompt, text: completion, avatar_id })
      );
      setTimeout(() => {
        handleCallback();
      }, 1500);
      return handleCancel();
    }
    postOutput({ prompt, text: content, avatar_id }, handleCallback);
    handleCancel();
  };

  const readSheet = (sheetName) => {
    const parsed = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
    setSamples(parsed);
  };

  const resetSheet = () => setSheetName("");

  const renderSelectSheet = () => {
    if (sheetNames.length > 0 && sheetName === "") {
      return (
        <SelectSheet
          sheetName={sheetName}
          sheetNames={sheetNames}
          resetSheet={resetSheet}
          setSheetName={setSheetName}
        />
      );
    }
  };

  const renderSamples = () => {
    if (Array.isArray(samples)) {
      return <p>{samples.length} samples will be imported.</p>;
    }
  };

  const renderForm = () => {
    if (bulk) {
      return (
        <div>
          <label className="mb-1">Upload a .csv or .xlsx file</label>
          <input
            type="file"
            className="form-control mb-3"
            onChange={(e) => setFile(e.target.files[0])}
          />
          {renderSelectSheet()}
          {renderSamples()}
        </div>
      );
    }
    return (
      <div>
        <label>Prompt</label>
        <input
          type="text"
          value={prompt}
          className="form-control mb-3"
          onChange={(e) => setPrompt(e.target.value)}
        />
        <label>Content</label>
        <input
          type="text"
          value={content}
          className="form-control mb-3"
          onChange={(e) => setContent(e.target.value)}
        />
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-6">
          <label>Bulk import?</label>
        </div>
        <div className="col-6 text-end">
          <ReactSwitch checked={bulk} onChange={setBulk} />
        </div>
      </div>
      {renderForm()}
      <div className="row mt-3">
        <div className="col-6">
          <button
            type="button"
            onClick={handleCancel}
            className="btn text-muted w-100"
          >
            Cancel
          </button>
        </div>
        <div className="col-6">
          <button className="btn btn-primary w-100">Save</button>
        </div>
      </div>
    </form>
  );
};

export default SingleOutputForm;
