import React, { useReducer, createContext, useContext } from "react";
import TrainingsService from "../services/TrainingsService";
import TrainingsReducer from "../reducers/TrainingsReducer";
import {
  SET_TRAINING,
  TRAININGS_RECEIVED,
  SET_PROPERTY_TRAINING,
} from "../types/trainings";
import { CREATE_TRAINING } from "../types/trainings";
import { ModalContext } from "./ModalContext";
import FilesService from "../services/FilesService";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import { navigate } from "@reach/router";

const initialState = {
  spinner: false,
  output: null,
  trainings: null,
  training: null,
};

export const TrainingsContext = createContext(initialState);

export const TrainingsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(TrainingsReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const handleError = (error) => {
    dispatch({ type: HIDE_SPINNER });
    if (error.response) {
      if (error.response.data) {
        if (error.response.data.error) {
          if (error.response.data.error.code) {
            return alert(`Open AI Error: ${error.response.data.error.message}`);
          }
        }
      }
      if (error.response.status === 412) {
        clearModal();
        return navigate("/sorry");
      }
    }
    alert(error);
  };

  const clearTrainings = () => {
    dispatch({ type: TRAININGS_RECEIVED, payload: null });
  };

  const getTrainings = (filters) => {
    TrainingsService.getMyTrainings(filters).then((res) => {
      const { trainings } = res.data;
      dispatch({ type: TRAININGS_RECEIVED, payload: trainings });
    });
  };

  const getSingleTraining = (training_id) => {
    TrainingsService.getSingleTraining(training_id).then((res) => {
      const { training } = res.data;
      dispatch({ type: SET_TRAINING, payload: training });
    });
  };

  const getPublicTrainings = () => {
    TrainingsService.getPublicTrainings().then((res) => {
      const { trainings } = res.data;
      dispatch({ type: TRAININGS_RECEIVED, payload: trainings });
    });
  };

  const setTraining = (training) => {
    dispatch({ type: SET_TRAINING, payload: training });
  };

  const createTraining = () => {
    dispatch({ type: CREATE_TRAINING });
  };

  const setPropertyTraining = (key, value) => {
    dispatch({ type: SET_PROPERTY_TRAINING, payload: { key, value } });
  };

  const saveTraining = (training, callback) => {
    dispatch({ type: SHOW_SPINNER });
    const promises = [];
    if (training.file && training.file !== null) {
      const formData = FilesService.getFormData(training.file);
      promises.push(
        new Promise((resolve, reject) => {
          FilesService.postFile(formData)
            .then((res) => {
              const { file_id } = res.data;
              training.file_id = file_id;
              resolve();
            })
            .catch(reject);
        })
      );
    }
    Promise.all(promises)
      .then(() => {
        let service = TrainingsService.putTraining;
        if (isNaN(parseInt(training.training_id))) {
          service = TrainingsService.postTraining;
        }
        service(training)
          .then(() => {
            dispatch({ type: HIDE_SPINNER });
            if (typeof callback === "function") {
              callback();
            }
            success("Training saved.");
            clearModal();
          })
          .catch(handleError);
      })
      .catch(handleError);
  };

  const executeTraining = (training) => {
    TrainingsService.executeTraining(training.training_id)
      .then(() => {
        getTrainings();
        success(
          `Training ${training.name} initiated. It may take up to 15 minutes to train a training.`
        );
      })
      .catch(handleError);
  };

  const syncTraining = (training, callback) => {
    TrainingsService.syncTraining(training.training_id)
      .then(() => {
        success(
          `Training ${training.name} synced. It may take up to 15 minutes to train a training.`
        );
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch(handleError);
  };

  const refineTraining = (training) => {
    TrainingsService.refineTraining(training.training_id)
      .then(() => {
        getTrainings();
        success(
          `Training ${training.name} synced. It may take up to 15 minutes to train a training.`
        );
      })
      .catch(handleError);
  };

  const deleteTraining = (training, callback) => {
    TrainingsService.deleteTraining(training.training_id)
      .then((res) => {
        success(`Training ${training.name} deleted`);
        if (typeof callback === "function") {
          callback();
        }
        clearModal();
      })
      .catch(handleError);
  };

  return (
    <TrainingsContext.Provider
      value={{
        ...state,
        setTraining,
        syncTraining,
        getTrainings,
        saveTraining,
        createTraining,
        clearTrainings,
        refineTraining,
        deleteTraining,
        executeTraining,
        getSingleTraining,
        getPublicTrainings,
        setPropertyTraining,
      }}
    >
      {children}
    </TrainingsContext.Provider>
  );
};
