import React, { createContext, useContext, useReducer } from "react";
import { SET_OUTPUT, PREPEND_OUTPUT, OUTPUTS_RECEIVED } from "../types/outputs";
import OutputsReducer from "../reducers/OutputsReducer";
import OutputsService from "../services/OutputsService";
import { ModalContext } from "./ModalContext";
import { navigate } from "@reach/router";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";

const initialState = {
  outputs: null,
  output: null,
  socket: null,
};

export const OutputsContext = createContext(initialState);

export const OutputsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(OutputsReducer, initialState);

  const { success, alert, clearModal } = useContext(ModalContext);

  const handleError = (error) => {
    if (error.response) {
      if (error.response.data) {
        if (error.response.data.error) {
          if (error.response.data.error.code) {
            return alert(`Open AI Error: ${error.response.data.error.message}`);
          }
        }
      }
      if (error.response.status === 412) {
        clearModal();
        return navigate("/sorry");
      }
    }
    alert(error);
  };

  const prependOutput = (output) => {
    dispatch({ type: PREPEND_OUTPUT, payload: output });
  };

  const getAvatarOutputs = (avatar_id, filters) => {
    OutputsService.getAvatarOutputs(avatar_id, filters).then((res) => {
      const { outputs } = res.data;
      dispatch({ type: OUTPUTS_RECEIVED, payload: outputs });
    });
  };

  const getTrainingOutputs = (training_id, filters) => {
    OutputsService.getTrainingOutputs(training_id, filters).then((res) => {
      const { outputs } = res.data;
      dispatch({ type: OUTPUTS_RECEIVED, payload: outputs });
    });
  };

  const setOutput = (output) => {
    dispatch({ type: SET_OUTPUT, payload: output });
  };

  const rateOutput = (output_id, liked) => {
    OutputsService.rateOutput({ output_id, liked })
      .then((res) => {
        success("Output rated.");
      })
      .catch(handleError);
  };

  const generateOutput = (data) => {
    OutputsService.generateOutput(data)
      .then((res) => {
        success("Outputs queued. They will appear under 'Outputs' tab.");
        clearModal();
      })
      .catch(handleError);
  };

  const createOutputFromMessage = (message) => {
    OutputsService.postOutputMessage(message)
      .then(() => {
        success("Output saved.");
        clearModal();
      })
      .catch(handleError);
  };

  const deleteOutput = (output_id, callback) => {
    OutputsService.deleteOutput(output_id)
      .then((res) => {
        success("Output deleted.");
        if (typeof callback === "function") {
          callback();
        }
        clearModal();
      })
      .catch(handleError);
  };

  const saveOutput = (output, callback) => {
    OutputsService.putOutput(output)
      .then((res) => {
        success("Output updated");
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch(handleError);
  };

  const postOutput = (output, callback) => {
    OutputsService.postOutput(output)
      .then((res) => {
        success("Output saved");
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch(handleError);
  };

  const downloadOutputs = (avatar, training, outputs) => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(outputs);
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      `${avatar.name}_${training.name}`
    );
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileName = `${avatar.name}_${training.name}_${moment().format(
      "YYYY_MM_DD_HH_mm"
    )}.xlsx`;
    const fileData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(fileData, fileName);
  };

  return (
    <OutputsContext.Provider
      value={{
        ...state,
        setOutput,
        postOutput,
        rateOutput,
        saveOutput,
        deleteOutput,
        prependOutput,
        generateOutput,
        downloadOutputs,
        getAvatarOutputs,
        getTrainingOutputs,
        createOutputFromMessage,
      }}
    >
      {children}
    </OutputsContext.Provider>
  );
};
