import React, { useState, useContext, useEffect } from "react";
import { AvatarsContext } from "../../context/AvatarsContext";
import { OutputsContext } from "../../context/OutputsContext";

const MessageOutputForm = ({ message, handleCancel, prevMessage }) => {
  const [avatarId, setAvatarId] = useState(null);
  const [trainingId, setTrainingId] = useState(null);
  const { avatars, getAvatars } = useContext(AvatarsContext);
  const { createOutputFromMessage } = useContext(OutputsContext);

  useEffect(() => {
    getAvatars();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (prevMessage) {
      message.prompt = prevMessage.content;
    }
    createOutputFromMessage({
      ...message,
      avatar_id: avatarId,
      training_id: trainingId,
    });
  };

  const renderAvatars = () => {
    if (Array.isArray(avatars)) {
      return [
        <option key="null" value={null}>
          Select Avatar...
        </option>,
        ...avatars.map((avatar) => (
          <option key={avatar.avatar_id} value={avatar.avatar_id}>
            {avatar.name}
          </option>
        )),
      ];
    }
  };

  const renderTrainings = () => {
    if (avatarId !== null) {
      const current = avatars.find(
        (avatar) => parseInt(avatar.avatar_id) === parseInt(avatarId)
      );
      if (current) {
        const { trainings } = current;
        if (Array.isArray(trainings)) {
          if (trainings.length > 0) {
            return (
              <div>
                <label>Add to Training (optional)</label>
                <select
                  value={trainingId}
                  className="form-control mb-3"
                  onChange={(e) => setTrainingId(e.target.value)}
                >
                  {[
                    <option key="null" value={null}>
                      Select Training...
                    </option>,
                    ...trainings.map((training) => (
                      <option
                        key={training.training_id}
                        value={training.training_id}
                      >
                        {training.name}
                      </option>
                    )),
                  ]}
                </select>
              </div>
            );
          }
        }
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>Select an Avatar</label>
      <select
        className="form-control mb-3"
        value={avatarId !== null ? avatarId : ""}
        onChange={(e) => setAvatarId(e.target.value)}
      >
        {renderAvatars()}
      </select>
      {renderTrainings()}
      <div className="row mt-3 align-items-center">
        <div className="col-6">
          <button
            type="button"
            onClick={handleCancel}
            className="btn text-muted"
          >
            Cancel
          </button>
        </div>
        <div className="col-6">
          <button type="submit" className="btn btn-primary w-100">
            Save Message
          </button>
        </div>
      </div>
    </form>
  );
};

export default MessageOutputForm;
