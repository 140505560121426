import React, { useState, useContext, useEffect } from "react";
import { OutputsContext } from "../../context/OutputsContext";
import { ModalContext } from "../../context/ModalContext";
import OutputEditForm from "./OutputEditForm";
import { copyToClipboard } from "../../utils";
import bunny from "../../assets/bunny.png";
import OutputInfo from "./OutputInfo";
import parse from "html-react-parser";
import moment from "moment";

const OutputCard = ({
  output,
  selected,
  selecting,
  handleSelect,
  handleDelete,
  handleCallback,
}) => {
  const [tags, setTags] = useState(null);
  const [copied, setCopied] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editedText, setEditedText] = useState(null);

  const { success } = useContext(ModalContext);
  const { saveOutput } = useContext(OutputsContext);
  const { modalComponent } = useContext(ModalContext);

  useEffect(() => {
    if (copied) {
      success("Copied to clipboard!");
    }
  }, [copied]);

  useEffect(() => {
    if (editMode) {
      setTags(output.tags);
      setEditedText(output.text);
    } else {
      setTags(null);
      setEditedText(null);
    }
  }, [editMode]);

  const handleCancel = () => setEditMode(false);

  const handleSave = () => {
    saveOutput({ ...output, tags, text: editedText }, handleCallback);
    setEditMode(false);
  };

  const handleInfo = () =>
    modalComponent("Output Details", <OutputInfo output={output} />);

  const handleCopy = () => copyToClipboard(output.text, () => setCopied(true));

  const getBorderClassName = () => {
    if (Array.isArray(selected)) {
      if (selected.includes(output.output_id)) {
        return "border-primary border-2";
      }
    }
    return "";
  };

  const renderBunny = () => {
    if (output.general_completion) {
      return (
        <img
          className="mx-2 d-inline-block"
          style={{ maxWidth: "25px" }}
          alt="Bemodo Fetch"
          src={bunny}
        />
      );
    }
  };

  const renderParams = () => {
    return (
      <div className="row mb-2 align-items-center">
        {selecting && Array.isArray(selected) && (
          <div className="col-1">
            <input
              type="checkbox"
              checked={selected.includes(output.output_id)}
              onChange={(e) => handleSelect(output.output_id)}
            />
          </div>
        )}
        <div className="col-11">
          <div className="d-flex align-items-center">
            <span className="small me-2 text-muted">
              {moment(output.createdAt).format("MMM Do YYYY HH:mm")}{" "}
            </span>
            {renderBunny()}
          </div>
        </div>
      </div>
    );
  };

  const renderOutput = () => {
    if (editMode && editedText !== null) {
      return (
        <OutputEditForm
          value={editedText}
          tagModifier={setTags}
          tagValues={output.tags}
          handleSave={handleSave}
          modifier={setEditedText}
          handleCancel={handleCancel}
        />
      );
    }
    if (output.text === "" || output.text === null) {
      if (output.createdAt !== output.updatedAt) {
        return "Output failed";
      }
      return "Still working on this...";
    }
    return (
      <p className="mb-0" style={{ fontSize: 16 }}>
        {parse(output.text.replace(/(?:\r\n|\r|\n)/g, "<br>"))}
      </p>
    );
  };

  const renderTags = () => {
    if (output.tags && output.tags !== null) {
      const tags = output.tags.split(",");
      return tags.map((tag) => (
        <span
          key={tag}
          className="badge text-capitalize badge-pill bg-accent me-2"
        >
          {tag}{" "}
        </span>
      ));
    }
  };

  return (
    <div className={`card output-card mb-2 ${getBorderClassName()}`}>
      <div className="card-body">
        {renderParams()}
        {renderOutput()}
      </div>
      <div className="card-footer pb-3 border-0 bg-white">
        <div className="row align-items-center">
          <div className="col-12 col-md-6">{renderTags()}</div>
          <div className="col-12 col-md-6 text-end">
            <button
              onClick={handleInfo}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Show details"
              className="btn btn-sm btn-round border me-2"
            >
              <i className="fa fa-info-circle small"></i>
            </button>
            <button
              onClick={() => handleDelete(output)}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Delete output"
              className="btn btn-sm btn-round btn-outline-danger me-2"
            >
              <i className="fa fa-trash"></i>
            </button>
            <button
              onClick={handleCopy}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Copy to clipboard"
              className="btn btn-sm btn-round border me-2"
            >
              <i className="fa fa-copy"></i>
            </button>
            <button
              onClick={() => setEditMode(!editMode)}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Edit output"
              className="btn btn-sm btn-round border me-2"
            >
              <i className="fa fa-edit"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutputCard;
