import React from "react";
import { formatMonto } from "../../utils";
import moment from "moment";
import StatusBadge from "../global/StatusBadge";

const PurchaseCard = ({ purchase, showCustomer, cancelSubscription }) => {
  const canCancel = () => {
    if (
      !showCustomer &&
      purchase.status === "active" &&
      typeof cancelSubscription === "function"
    ) {
      return (
        <button
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Cancel Subscription"
          className="btn btn-round btn-sm btn-outline-danger"
          onClick={() => cancelSubscription(purchase)}
        >
          <i className="fa fa-times"></i>
        </button>
      );
    }
  };

  const renderDiscount = () => {
    if (purchase.discount && purchase.discount !== null) {
      return purchase.discount.code;
    }
  };

  const renderExpiration = () => {
    if (purchase.expiration_date !== null) {
      return moment(purchase.expiration_date).format("DD MMM YYYY HH:mm");
    }
    if (purchase.subscription_id !== null && purchase.status === "cancelled") {
      let free_trial_cancel = moment(purchase.createdAt);
      if (purchase.free_trial_length !== null) {
        free_trial_cancel.add(purchase.free_trial_length, "days");
      }
      let cancel_expiration = moment(purchase.updatedAt).add(
        purchase.subscription_interval,
        purchase.subscription_period
      );
      let expiration_date = cancel_expiration;
      if (free_trial_cancel.isAfter(cancel_expiration)) {
        expiration_date = free_trial_cancel;
      }
      return expiration_date.format("MMM Do YYYY");
    }
  };

  const renderCustomer = () => {
    if (showCustomer && purchase.user && purchase.user !== null) {
      return (
        <td>
          {purchase.user.name}: {purchase.user.email}
        </td>
      );
    }
  };

  return (
    <tr className="border text-dark">
      <td className="id">{purchase.purchase_id}</td>
      {renderCustomer()}

      <td>
        <StatusBadge status={purchase.status} order_id={purchase.order_id} />
      </td>
      <td className="datetime">
        {moment(purchase.createdAt).format("MMM Do YYYY HH:mm")}
      </td>
      <td>
        {"$"}
        {formatMonto(purchase.amount)}
      </td>
      <td>{renderDiscount()}</td>
      <td className="datetime">{renderExpiration()}</td>
      <td>{canCancel()}</td>
    </tr>
  );
};

export default PurchaseCard;
