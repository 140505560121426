import React, { useContext, useEffect } from "react";
import Generator from "./Generator";
import { MenuContext } from "../context/MenuContext";

const HashtagGenerator = () => {
  const { setSelected } = useContext(MenuContext);

  useEffect(() => {
    setSelected("Hashtag Generator");
  }, []);

  return (
    <Generator
      base_prompt="Generate 10 to 20 trending hashtags ideas for "
      title="Hashtag Generator"
      message_type_id={2}
    />
  );
};

export default HashtagGenerator;
