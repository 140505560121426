import React from "react";
import InvoiceCard from "./InvoiceCard";

const InvoicesTable = ({ invoices }) => {
  const renderOrdenes = () => {
    if (Array.isArray(invoices)) {
      if (invoices.length === 0) {
        return <p className="mt-3">No has hecho ninguna compra.</p>;
      }
      return invoices.map((invoice) => (
        <InvoiceCard key={invoice.invoice_id} invoice={invoice} />
      ));
    }
  };

  return (
    <div className="table-responsive purchases-table">
      <table className="table">
        <thead>
          <tr className="bg-light border bold py-2">
            <th className="id">#ID</th>
            <th>Total</th>
            <th>Status</th>
            <th className="datetime">Date</th>
            <th>Next on</th>
          </tr>
        </thead>
        <tbody>{renderOrdenes()}</tbody>
      </table>
    </div>
  );
};

export default InvoicesTable;
