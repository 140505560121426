import api from "./api";
import { getArgs } from "../utils";

const route = "/avatars";

const AvatarsService = {
  getMyAvatars: (filters) => api.get(`${route}?${getArgs(filters)}`),
  getPublicAvatars: () => api.get(`${route}/public/all`),
  getSingleAvatar: (avatar_id) => api.get(`${route}/${avatar_id}`),
  postAvatar: (avatar) => api.post(route, { ...avatar }),
  putAvatar: (avatar) => api.put(route, { ...avatar }),
  deleteAvatar: (avatar_id) => api.delete(`${route}/${avatar_id}`),
  refineAvatar: (avatar_id) => api.post(`${route}/refine`, { avatar_id }),
  combineAvatar: (
    selected_avatar_id,
    target_avatar_id,
    target_training_id,
    name
  ) =>
    api.post(`${route}/combine`, {
      selected_avatar_id,
      target_avatar_id,
      target_training_id,
      name,
    }),
};
export default AvatarsService;
