import api from "./api";
import { getArgs } from "../utils";

const route = "/purchases";

const PurchasesService = {
  getPurchases: () => api.get(route),
  getPurchasesAdmin: (filters) => api.get(`${route}/admin?${getArgs(filters)}`),
  getSinglePurchase: (purchase_id) => api.get(`${route}/${purchase_id}`),
  getPurchase: (purchase_id) => api.get(`${route}/${purchase_id}`),
  cancelSubscription: (purchase_id, cancel_reason) =>
    api.post(`${route}/cancel/${purchase_id}`, {
      cancel_reason,
      status: "cancelled",
    }),
};

export default PurchasesService;
