import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import { MenuContext } from "../context/MenuContext";
import ReactSwitch from "react-switch";
import { Link } from "@reach/router";

const Settings = () => {
  const [editMode, setEditMode] = useState(false);
  const { user, updateUser, setPropertyUser } = useContext(AuthContext);
  const { setSelected } = useContext(MenuContext);

  useEffect(() => {
    setSelected("Settings");
  }, []);

  return (
    <div className="container py-3">
      <h1 className="mb-3">Settings</h1>
      <p>
        You need a valid OpenAI API Key to use BemodoAI Avatars.{" "}
        <Link to="/tutorials/1">Learn more.</Link>{" "}
      </p>
      <div className="card p-3 shadow-sm">
        <div className="row my-3 align-items-center">
          <div className="col-12 col-md-6">
            <h4>API Key</h4>
            <p className="small">Required to train your own custom avatar.</p>
          </div>
          <div className="col-10 col-md-5">
            <input
              type="text"
              disabled={!editMode}
              value={user.api_key}
              className="form-control"
              onChange={(e) => setPropertyUser("api_key", e.target.value)}
            />
          </div>
          <div className="col-2 col-md-1 px-0 text-center">
            <button
              className="btn px-0 btn-sm d-inline-block"
              onClick={() => setEditMode(!editMode)}
            >
              <i className={`fa fa-${editMode ? "check" : "edit"}`} />
            </button>
          </div>
        </div>
        <div className="row my-3 align-items-center">
          <div className="col-12 col-md-6">
            <h4>Manual Mode</h4>
            <p className="small">
              You will have to write all prompts. If you know your game, this
              will produce better results.
            </p>
          </div>
          <div className="col-12 col-md-6">
            <ReactSwitch
              checked={user.manual_mode}
              onChange={(checked) => setPropertyUser("manual_mode", checked)}
            />
          </div>
        </div>
        <div className="row my-3 align-items-center">
          <div className="col-12 col-md-6">
            <h4>Dark Mode</h4>
          </div>
          <div className="col-12 col-md-6">
            <ReactSwitch
              checked={user.dark_mode}
              onChange={(checked) => setPropertyUser("dark_mode", checked)}
            />
          </div>
        </div>
        <button
          className="btn btn-primary w-100"
          onClick={() => updateUser(user)}
        >
          <i className="fa fa-save me-2"></i> Save Settings
        </button>
      </div>
    </div>
  );
};

export default Settings;
