import React, { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";

const Modal = () => {
  const {
    title,
    content,
    clearModal,
    showModal,
    component,
    children,
    onCancel,
    callback,
    fullscreen,
  } = useContext(ModalContext);

  const handleCancel = () => {
    onCancel();
    clearModal();
  };

  return (
    <div
      className="modal fade"
      id="modal"
      tabIndex="-1"
      role="dialog"
      data-bs-backdrop="static"
      aria-labelledby="modal"
      aria-hidden={!showModal}
    >
      <div
        className={`modal-dialog ${fullscreen ? "modal-fullscreen" : ""}`}
        role="document"
      >
        <div className="modal-content">
          {title && (
            <div className="modal-header pb-0 border-0">
              <h5 className="modal-title">{title}</h5>
            </div>
          )}
          <div className="modal-body">
            {content}
            {component}
            {children}
          </div>
          {(callback || onCancel) && (
            <div className="modal-footer">
              <div className="container-fluid px-0">
                <div className="row align-items-center">
                  <div className="col-6">
                    <button
                      type="button"
                      className="btn text-muted w-100"
                      data-dismiss="modal"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      type="button"
                      className="btn btn-primary w-100"
                      onClick={callback}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
