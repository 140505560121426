import React, { useContext, useEffect } from "react";
import SingleAvatarTabs from "../components/avatars/SingleAvatarTabs";
import AvatarDetails from "../components/avatars/AvatarDetails";
import AvatarHeader from "../components/avatars/AvatarHeader";
import { AvatarsContext } from "../context/AvatarsContext";
import { MenuContext } from "../context/MenuContext";
import CombinedView from "./CombinedView";
import { Router } from "@reach/router";
import Outputs from "./Outputs";

const SingleAvatar = ({ avatar_id }) => {
  const { setSelected } = useContext(MenuContext);
  const { avatar, getSingleAvatar } = useContext(AvatarsContext);

  useEffect(() => {
    getSingleAvatar(avatar_id);
  }, [avatar_id]);

  useEffect(() => {
    if (avatar && avatar !== null) {
      setSelected(avatar.name);
    }
  }, [avatar]);

  const renderView = () => {
    if (avatar && avatar !== null) {
      return (
        <>
          <AvatarHeader fetchOutputs={() => getSingleAvatar(avatar_id)} />
          <div className="show-mobile mb-4">
            <SingleAvatarTabs avatar={avatar} />
          </div>
          <div id="avatar-content">
            <Router>
              <CombinedView path="/" avatar_id={avatar_id} default />
              <Outputs path="/outputs" />
              <AvatarDetails path="/details" />
            </Router>
          </div>
        </>
      );
    }
  };

  return (
    <div id="avatar" className="container-fluid">
      {renderView()}
    </div>
  );
};

export default SingleAvatar;
