import React, { createContext, useReducer, useContext } from "react";
import TemplatesService from "../services/TemplatesService";
import TemplatesReducer from "../reducers/TemplatesReducer";
import {
  SET_TEMPLATE,
  CREATE_TEMPLATE,
  TEMPLATES_RECEIVED,
  SET_PROPERTY_TEMPLATE,
} from "../types/templates";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  templates: null,
  template: null,
  spinner: false,
};

export const TemplatesContext = createContext(initialState);

export const TemplatesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(TemplatesReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getTemplates = (filters) => {
    dispatch({ type: SHOW_SPINNER });
    TemplatesService.getTemplates(filters)
      .then((response) => {
        dispatch({ type: HIDE_SPINNER });
        const { templates } = response.data;
        dispatch({ type: TEMPLATES_RECEIVED, payload: templates });
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const getPublicTemplates = (filters) => {
    dispatch({ type: SHOW_SPINNER });
    TemplatesService.getPublicTemplates(filters)
      .then((response) => {
        dispatch({ type: HIDE_SPINNER });
        const { templates } = response.data;
        dispatch({ type: TEMPLATES_RECEIVED, payload: templates });
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const getAllTemplates = (filters) => {
    dispatch({ type: SHOW_SPINNER });
    TemplatesService.getAllTemplates(filters)
      .then((response) => {
        dispatch({ type: HIDE_SPINNER });
        const { templates } = response.data;
        dispatch({ type: TEMPLATES_RECEIVED, payload: templates });
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const getSingleTemplate = (template_id) => {
    TemplatesService.getSingleTemplates(template_id)
      .then((response) => {
        const { template } = response.data;
        dispatch({ type: SET_TEMPLATE, payload: template });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setTemplate = (template) => {
    dispatch({ type: SET_TEMPLATE, payload: template });
  };

  const createTemplate = () => {
    dispatch({ type: CREATE_TEMPLATE });
  };

  const setPropertyTemplate = (key, value) => {
    dispatch({ type: SET_PROPERTY_TEMPLATE, payload: { key, value } });
  };

  const clearTemplates = () => {
    dispatch({ type: TEMPLATES_RECEIVED, payload: null });
  };

  const saveTemplate = (template, callback) => {
    dispatch({ type: SHOW_SPINNER });
    let service = TemplatesService.putTemplate;
    if (isNaN(parseInt(template.template_id))) {
      service = TemplatesService.postTemplate;
    }
    service(template)
      .then(() => {
        success("Template saved.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const toggleFavorite = (template_id, callback) => {
    TemplatesService.favoriteTemplate(template_id).then(() => {
      success("Favorite saved!");
      if (typeof callback === "function") {
        callback();
      }
    });
  };

  const deleteTemplate = (template_id, callback) => {
    dispatch({ type: SHOW_SPINNER });
    TemplatesService.deleteTemplate(template_id)
      .then(() => {
        success("Template deleted.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  return (
    <TemplatesContext.Provider
      value={{
        ...state,
        setTemplate,
        getTemplates,
        saveTemplate,
        toggleFavorite,
        clearTemplates,
        deleteTemplate,
        createTemplate,
        getAllTemplates,
        getSingleTemplate,
        getPublicTemplates,
        setPropertyTemplate,
      }}
    >
      {children}
    </TemplatesContext.Provider>
  );
};
