import React, { useContext, useEffect } from "react";
import PurchaseCard from "./PurchaseCard";
import CancelForm from "./CancelForm";
import { ModalContext } from "../../context/ModalContext";
import { PurchasesContext } from "../../context/PurchasesContext";
import { setupTooltips } from "../../utils";

const PurchasesTable = ({ purchases, showCustomer }) => {
  const { cancelSubscription } = useContext(PurchasesContext);
  const { modalComponent } = useContext(ModalContext);

  useEffect(() => {
    setupTooltips();
  }, [purchases]);

  const confirmCancel = (purchase) => {
    modalComponent(
      "Caution",
      <CancelForm purchase={purchase} cancelSubscription={cancelSubscription} />
    );
  };
  const renderOrdenes = () => {
    if (Array.isArray(purchases)) {
      if (purchases.length === 0) {
        return <p className="mt-3">You haven't made any purchases.</p>;
      }
      return purchases.map((purchase) => (
        <PurchaseCard
          purchase={purchase}
          key={purchase.purchase_id}
          showCustomer={showCustomer}
          cancelSubscription={confirmCancel}
        />
      ));
    }
  };

  return (
    <div className="table-responsive purchases-table">
      <table className="table">
        <thead>
          <tr className="bg-light border bold py-2">
            <th className="id">#ID</th>
            {showCustomer && <th>Customer</th>}
            <th>Status</th>
            <th className="datetime">Date</th>
            <th>Total</th>
            <th>Discount</th>
            <th className="datetime">Expires on</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{renderOrdenes()}</tbody>
      </table>
    </div>
  );
};

export default PurchasesTable;
