import axios from "axios";
import { API_URL } from "../utils";
import AuthService from "./FirebaseService";

const api = axios.create({
  baseURL: API_URL,
  retryDelay: 3000,
  retry: 3,
});

api.interceptors.request.use(function (config) {
  // Do something before request is sent
  return config;
});

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const { config, message } = error;
    console.log("error");
    console.log(error);
    if (!config || !config.retry) {
      return Promise.reject(error);
    }
    // retry while Network timeout or Network Error
    if (!(message.includes("timeout") || message.includes("Network Error"))) {
      return Promise.reject(error);
    }
    config.retry -= 1;
    if (error.response.data) {
      if (error.response.data.code) {
        if (error.response.data.code === "auth/id-token-expired") {
          return AuthService.handleToken().then(() => {
            return setTimeout(() => {
              axios(error.config);
            }, error.config.retryDelay);
          });
        }
      }
    }
    return Promise.reject(error);
  }
);

export default api;
