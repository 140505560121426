import React, { useContext, useEffect } from "react";
import Sorry from "./Sorry";
import Upgrade from "./Upgrade";
import Avatars from "./Avatars";
import Profile from "./Profile";
import Settings from "./Settings";
import ThankYou from "./ThankYou";
import Admin from "./admin/Admin";
import Templates from "./Templates";
import Tutorials from "./Tutorials";
import Purchases from "./Purchases";
import SingleAvatar from "./SingleAvatar";
import Conversations from "./Conversations";
import SingleTutorial from "./SingleTutorial";
import TopicsGenerator from "./TopicsGenerator";
import HashtagGenerator from "./HashtagGenerator";
import Sidebar from "../components/global/Sidebar";
import { Router, useLocation } from "@reach/router";
import { AuthContext } from "../context/AuthContext";
import SingleConversation from "./SingleConversation";
import MenuMobile from "../components/navbar/MenuMobile";

const Panel = () => {
  const location = useLocation();
  const { user, getCurrentUser } = useContext(AuthContext);

  useEffect(() => {
    if (user !== null) {
      getCurrentUser();
    }
  }, [location]);

  return (
    <div className="container-fluid ps-0">
      <Sidebar />
      <MenuMobile />
      <div className="content pb-4">
        <Router>
          <Sorry path="/sorry" />
          <Admin path="/admin/*" />

          <Upgrade path="/upgrade" />
          <Profile path="/profile" />
          <Conversations path="/*" />
          <Avatars path="/avatars" />
          <Settings path="/settings" />
          <Purchases path="/billing" />
          <Templates path="/templates" />
          <Tutorials path="/tutorials" />
          <TopicsGenerator path="/topics" />
          <HashtagGenerator path="/hashtags" />
          <ThankYou path="/thankyou/:purchase_id" />
          <SingleAvatar path="/avatars/:avatar_id/*" />
          <SingleTutorial path="/tutorials/:tutorial_id" />
          <SingleConversation path="/conversations/:conversation_id" />
        </Router>
      </div>
    </div>
  );
};

export default Panel;
