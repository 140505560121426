import React, { useContext, useEffect, useState } from "react";
import TemplateForm from "./TemplateForm";
import TemplateCard from "./TemplateCard";
import { ModalContext } from "../../context/ModalContext";
import { TemplatesContext } from "../../context/TemplatesContext";
import TemplateInfo from "./TemplateInfo";
import { setupTooltips } from "../../utils";

const TemplateList = ({
  size,
  title,
  hideEdit,
  templates,
  avatar_id,
  hideCreate,
  handleApply,
  fetchTemplates,
}) => {
  const [query, setQuery] = useState("");
  const [showFavorites, setShowFavorites] = useState(false);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { spinner, setTemplate, clearTemplates, createTemplate } =
    useContext(TemplatesContext);

  useEffect(() => {
    if (query === "") {
      clearTemplates();
    }
    if (!spinner) {
      fetchTemplates({ query, favorites: showFavorites });
    }
  }, [query, showFavorites]);

  useEffect(() => {
    setupTooltips();
  }, [templates]);

  const handleCallback = () =>
    fetchTemplates({ query, favorites: showFavorites });

  const handleInfo = () => {
    if (size !== "lg") {
      clearModal();
      setTimeout(() => {
        modalComponent("About Templates", <TemplateInfo />);
      }, 500);
    } else {
      modalComponent("About Templates", <TemplateInfo />);
    }
  };

  const handleCancelTemplate = () => {
    clearModal();
    setTemplate(null);
  };

  const handleCreateTemplate = () => {
    createTemplate();
    if (size !== "lg") {
      clearModal();
      setTimeout(() => {
        modalComponent(
          "Add Template",
          <TemplateForm
            handleCancel={handleCancelTemplate}
            handleCallback={handleCallback}
          />
        );
      }, 500);
    } else {
      modalComponent(
        "Add Template",
        <TemplateForm
          handleCancel={handleCancelTemplate}
          handleCallback={handleCallback}
        />
      );
    }
  };

  const renderTemplates = () => {
    if (Array.isArray(templates)) {
      if (templates.length === 0) {
        return <p>No templates available.</p>;
      }
      return templates.map((template) => (
        <div
          key={template.template_id}
          className={`col-12 ${size === "lg" ? "col-md-6 col-xl-4" : ""}`}
        >
          <TemplateCard
            handleCallback={handleCallback}
            handleApply={handleApply}
            avatar_id={avatar_id}
            hideEdit={hideEdit}
            template={template}
            size={size}
          />
        </div>
      ));
    }
    if (spinner) return <div className="spinner-border"></div>;
  };

  return (
    <div id="templates" className="container-fluid ps-0">
      <div className="row align-items-center">
        <div className="col-8">
          {size === "lg" ? (
            <h1 className="mb-0 text-capitalize text-gradient d-inline-block">
              {title}
            </h1>
          ) : (
            <h3 className="mb-0 text-capitalize">{title}</h3>
          )}
        </div>
        <div className="col-4 text-end">
          <button
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Show favorites only"
            className={`btn btn-round btn-sm ${
              showFavorites > 0 ? "btn-warning text-dark" : "border"
            } me-2`}
            onClick={() => setShowFavorites(!showFavorites)}
          >
            <i className="fa fa-star"></i>
          </button>
          <button onClick={handleInfo} className="btn btn-round border btn-sm">
            <i className="fa fa-info-circle"></i>
          </button>
        </div>
      </div>
      <div className="row align-items-center">
        <div className={`mb-2 col-12 col-md-${size === "lg" ? "8" : "12"}`}>
          <input
            type="text"
            value={query}
            className="form-control mt-4 mb-3"
            placeholder="Search templates..."
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        <div className={`mb-2 col-12 col-md-${size === "lg" ? "4" : "12"}`}>
          {!hideCreate && (
            <button
              className="btn btn-outline-primary w-100"
              onClick={handleCreateTemplate}
            >
              <i className="fa fa-plus me-2"></i>
              Template
            </button>
          )}
        </div>
      </div>
      <div className="row">{renderTemplates()}</div>
    </div>
  );
};

export default TemplateList;
