import React, { useContext } from "react";
import TemplateForm from "./TemplateForm";
import TemplateApplyForm from "./TemplateApplyForm";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import { TemplatesContext } from "../../context/TemplatesContext";

const TemplateCard = ({
  size,
  avatar_id,
  template,
  hideEdit,
  handleApply,
  handleCallback,
}) => {
  const { user } = useContext(AuthContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { setTemplate, toggleFavorite } = useContext(TemplatesContext);

  const handleCancel = () => {
    setTemplate(null);
    clearModal();
  };

  const handleView = () => {
    modalComponent(
      template.name,
      <div>
        <p>{template.content}</p>
        <button onClick={clearModal} className="btn btn-primary w-100">
          OK
        </button>
      </div>
    );
  };

  const handleEdit = () => {
    setTemplate(template);
    modalComponent(
      "Edit Template",
      <TemplateForm
        handleCancel={handleCancel}
        handleCallback={handleCallback}
      />
    );
  };

  const handleTemplate = () => {
    setTemplate(template);
    if (size === "lg") {
      modalComponent(
        "Apply Template",
        <TemplateApplyForm
          avatar_id={avatar_id}
          handleApply={handleApply}
          handleCancel={handleCancel}
        />
      );
    } else {
      clearModal();
      setTimeout(() => {
        modalComponent(
          "Apply Template",
          <TemplateApplyForm
            avatar_id={avatar_id}
            handleApply={handleApply}
            handleCancel={handleCancel}
          />
        );
      }, 500);
    }
  };

  const handleFavorite = () => {
    toggleFavorite(template.template_id, handleCallback);
  };

  const renderTags = () => {
    if (template.tags !== null) {
      let tags = String(template.tags).split(",");
      return tags.map((tag) => (
        <span key={tag} className="badge badge-pill bg-accent m-1">
          {tag}
        </span>
      ));
    }
  };

  return (
    <div className="card bg-light pb-2 mb-3">
      <div className="card-header pb-0 pt-3 bg-light border-0">
        <h3 className="h5 bold small">{template.name}</h3>
      </div>
      <div className="card-body py-0 bg-light border-0">
        <p className="small text-muted mb-0">
          {String(template.content).substring(0, 255)}
        </p>
        {String(template.content).length > 255 && (
          <button
            onClick={handleView}
            className="btn btn-link no-decoration text-primary px-0"
          >
            More...
          </button>
        )}
      </div>
      <div className="card-footer bg-light border-0">
        <div className="row align-items-center">
          <div className="col-6">
            {template.public && template.public !== null && (
              <span className="badge badge-pill bg-primary m-1">Public</span>
            )}
            <span className="badge badge-pill bg-accent m-1">
              {template.type}
            </span>
            {renderTags()}
          </div>
          <div className="col-6 text-end">
            {user.user_id === template.user_id && !hideEdit && (
              <button
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Edit content"
                className="btn btn-round btn-sm border me-2"
                onClick={handleEdit}
              >
                <i className="fa fa-edit"></i>
              </button>
            )}
            <button
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Set as favorite"
              className={`btn btn-round btn-sm ${
                template.favorites.length > 0
                  ? "btn-warning text-dark"
                  : "border"
              } me-2`}
              onClick={handleFavorite}
            >
              <i className="fa fa-star"></i>
            </button>
            <button
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Apply template"
              className="btn btn-round btn-sm btn-primary"
              onClick={handleTemplate}
            >
              <i className="fa fa-chevron-right mx-1"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateCard;
