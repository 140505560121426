import React, { useRef, useContext } from "react";
import { CapacitorContext } from "../../context/CapacitorContext";
import { MenuContext } from "../../context/MenuContext";
import { AuthContext } from "../../context/AuthContext";
import { navigate } from "@reach/router";
import bunny from "../../assets/bunny.png";
import { hasNotch } from "../../utils";

const MenuMobile = () => {
  const toggleMenuButton = useRef(null);
  const { signOut } = useContext(AuthContext);

  const { tabs, selected } = useContext(MenuContext);

  const { device, platform } = useContext(CapacitorContext);

  const renderHeader = () => {
    if (selected && selected !== null) {
      return (
        <div className="d-inline">
          <span className="ms-2">{selected}</span>
        </div>
      );
    }
  };

  const renderMenuButton = () => {
    if (platform === "web") {
      return (
        <button
          type="button"
          ref={toggleMenuButton}
          className="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
      );
    }
  };

  const renderTabs = () => {
    if (tabs) {
      return tabs.map((tab) => (
        <div
          className="p-2 text-dark hover-accent no-decoration border-top border-bottom"
          key={tab.name}
          onClick={() => {
            toggleMenuButton.current.click();
            navigate(tab.link);
          }}
        >
          <div className="row w-100">
            <div className="col-1">
              {tab.name === "Fetch" ? (
                <img
                  src={bunny}
                  alt="Bemodo Bunny"
                  style={{ maxWidth: 25, marginLeft: -6 }}
                  className="d-inline-block"
                />
              ) : (
                <i className={tab.icon} />
              )}
            </div>
            <div className="col-11"> {tab.name}</div>
          </div>
        </div>
      ));
    }
  };

  const renderHomeButton = () => {
    if (platform !== "web") {
      return (
        <button className="btn me-1" onClick={() => navigate("/")}>
          <i className="fa fa-home"></i>
        </button>
      );
    }
  };

  return (
    <nav
      style={{ top: 32 }}
      className="navbar navbar-expand-lg navbar-light bg-white text-dark fixed-top border-bottom px-3 show-mobile"
    >
      <div className="col-12">
        <div
          style={{
            paddingTop: hasNotch(device) ? 50 : 0,
          }}
          className="row mx-0 align-items-center"
        >
          <div className="col-6 px-0">
            <div className="navbar-brand py-0 me-auto">
              {" "}
              <img
                src={bunny}
                alt="Bemodo Bunny"
                style={{ maxWidth: 45 }}
                className="d-inline-block"
              />{" "}
              {renderHeader()}
            </div>
          </div>
          <div className="col-6 pe-0 text-end">
            {renderHomeButton()}
            {renderMenuButton()}
          </div>
        </div>
        <div className="collapse navbar-collapse mw-100 pt-3" id="navbarNav">
          <nav className="navbar-nav me-auto text-start">
            {renderTabs()}
            <button
              className="btn btn-sm text-start text-danger mx-2 px-0 my-3"
              onClick={signOut}
            >
              <i className="fas fa-sign-out-alt fa-flip-horizontal me-2" /> Log
              Out
            </button>
          </nav>
        </div>
      </div>
    </nav>
  );
};

export default MenuMobile;
